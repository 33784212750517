import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  ActivityImagesDataType,
  LogQuantityQuestion,
  InsertNewHabitPayload,
  RoutineSuggestionPayload
} from 'interfaces';
import {
  AiToneOptions,
  CUSTOM_ROUTINE_TRIGGER,
  FLAG,
  FONT_OPTION,
  Mode,
  THEME_OPTION
} from 'constants/enum';
import {
  ACTIVITY_TYPES,
  DEFAULT_DURATION,
  HABIT_SETTING_FEATURES,
  ROUTINE_DESTINATION_POSITION,
  ROUTINE_PLAYING_TYPE,
  SPLICE_CLONE_COUNT,
  SPLICE_DELETE_COUNT,
  SPLICE_INSERT_COUNT,
  TABS,
  NUMBERS,
  SPLICE_COPY_COUNT,
  USER_SETTINGS_KEYS,
  EMPTY_STRING,
  ROUTINE,
  TAB,
  ACTIVITY_SEQUENCE_TYPE,
  CUSTOM_ROUTINE_FEATURE
} from 'constants/general';
import { INIT_CUSTOM_ROUTINE, INIT_NEW_HABIT } from 'assets/data';
import { v4 as uuid } from 'uuid';
import {
  getLibraryActivities,
  getRoutineSummary,
  getUserSettings,
  initUserSettings,
  saveUserSettings,
  validatePastedSettings,
  getUserStatsLeaderBoard,
  getRoutineSuggestionActivities
} from './extra';
import {
  filterOutCutoffTimeAndTutorial,
  getActivitiesOfSettings,
  getRoutineActivitiesFromSuggestions,
  getRoutineValue
} from 'utils/support';
import { initialSettingState } from 'store/initial-states';
import { ActivityType, ChoiceType } from 'interfaces/commonInterface';
import {
  CustomRoutineType,
  FeedbackType,
  ROUTINE_TIME_VALIDATION,
  SettingsType,
  ValidationError
} from 'interfaces/settingInterface';

const setting = createSlice({
  name: 'setting',
  initialState: initialSettingState,
  reducers: {
    changeShowDropProcessor: (state, action) => {
      state.showDropProcessor = action.payload;
    },
    changeShowFreeStyle: (state, action) => {
      state.showFreeStyle = action.payload;
    },
    changeShowChoice: (state, action) => {
      state.showChoice = action.payload;
    },
    changeShowTabs: (state, action) => {
      state.showTabs = action.payload;
    },
    changeLoadingSchema: (state, action) => {
      state.loadingSchema = action.payload;
    },
    changeIsValidateSettings: (state, action) => {
      state.isValidSettings = action.payload;
    },
    changeIsSettingLoading: (state, action) => {
      state.isSettingLoading = action.payload;
    },
    changeIsSettingLoaded: (state, action) => {
      state.isSettingLoaded = action.payload;
    },
    updateShutdownTime: (state, { payload }) => {
      state.currentSettings.shutdown_time = payload;
    },
    updateStartupTime: (state, { payload }) => {
      state.currentSettings.startup_time = payload;
    },
    changeBreakingInterval: (state, { payload }) => {
      state.currentSettings.break_after_minutes = payload;
    },
    updateActivityFromMoreOptions: (
      state,
      {
        payload: { type, position, value, customRoutinePosition }
      }: PayloadAction<{
        type: string;
        position: number;
        value: ActivityType;
        customRoutinePosition?: number;
      }>
    ) => {
      const activities = getActivitiesOfSettings(
        type,
        state,
        customRoutinePosition
      );
      activities[position] = value;
      if (type === ROUTINE.CUSTOM_ROUTINES) {
        state.currentSettings = {
          ...state.currentSettings,
          custom_routines: state.currentSettings.custom_routines?.map(
            (routine, idx) =>
              idx === customRoutinePosition
                ? { ...routine, standalone_activities: activities }
                : routine
          )
        };
      } else {
        state.currentSettings = {
          ...state.currentSettings,
          [type]: activities
        };
      }
    },
    updateSettingFeatures: (
      state,
      {
        payload: { type, position, feature, value, customRoutinePosition }
      }: PayloadAction<{
        type: string;
        position: number;
        feature: string;
        value:
          | string
          | number
          | boolean
          | string[]
          | ActivityImagesDataType[]
          | null;
        customRoutinePosition?: number;
      }>
    ) => {
      const activities = getActivitiesOfSettings(
        type,
        state,
        customRoutinePosition
      );
      switch (feature) {
        case HABIT_SETTING_FEATURES.NAME:
          activities[position].name = value as string;
          break;
        case HABIT_SETTING_FEATURES.DURATION:
          activities[position].duration_seconds = value as number;
          break;
        case HABIT_SETTING_FEATURES.COMPLETION_REQUIREMENTS:
          activities[position].completion_requirements = value as string | null;
      }
      if (type === ACTIVITY_TYPES.LIBRARY) {
        state.activityLibrary = [...activities];
      } else if (
        state.currentSettings.custom_routines?.length &&
        typeof customRoutinePosition === 'number'
      ) {
        state.currentSettings.custom_routines[
          customRoutinePosition
        ].standalone_activities = activities;
      } else {
        state.currentSettings = {
          ...state.currentSettings,
          [type]: activities
        };
      }
    },
    changeIncludeEveryBreak: (
      state,
      {
        payload: { position, data }
      }: PayloadAction<{ position: number; data: boolean }>
    ) => {
      if (state.currentSettings.break_activities) {
        state.currentSettings.break_activities[
          position
        ].include_in_every_break = data;
      }
    },
    changeIsOfficeFriendly: (
      state,
      {
        payload: { position, data }
      }: PayloadAction<{ position: number; data: boolean }>
    ) => {
      if (state.currentSettings.break_activities) {
        state.currentSettings.break_activities[position].is_office_friendly =
          data;
      }
    },
    changeCurrentSettings: (
      state,
      { payload }: PayloadAction<SettingsType>
    ) => {
      state.currentSettings = payload;
    },
    updatePreviousSettings: (
      state,
      { payload }: PayloadAction<SettingsType>
    ) => {
      state.previousSettings = payload;
    },
    newHabit: (
      state,
      {
        payload: {
          type,
          sequence_id,
          category,
          isFreeTimeHabit,
          freeTimeHabit,
          customRoutinePosition
        }
      }: PayloadAction<{
        type: string;
        sequence_id?: string;
        category?: string;
        isNewHabitAppended?: boolean;
        isFreeTimeHabit?: boolean;
        freeTimeHabit?: ActivityType;
        customRoutinePosition?: number;
      }>
    ) => {
      switch (type) {
        case ACTIVITY_TYPES.MORNING:
          if (state.currentSettings.morning_activities) {
            state.currentSettings.morning_activities.push({
              ...INIT_NEW_HABIT(),
              activity_sequence_id: sequence_id,
              activity_type: ACTIVITY_SEQUENCE_TYPE.MORNING
            });
          }
          break;
        case ACTIVITY_TYPES.EVENING:
          if (state.currentSettings.evening_activities) {
            state.currentSettings.evening_activities.push(
              isFreeTimeHabit && freeTimeHabit
                ? freeTimeHabit
                : {
                    ...INIT_NEW_HABIT(),
                    activity_sequence_id: sequence_id,
                    duration_seconds: DEFAULT_DURATION.EVENING,
                    activity_type: ACTIVITY_SEQUENCE_TYPE.EVENING
                  }
            );
          }
          break;
        case ACTIVITY_TYPES.BREAKING:
          if (state.currentSettings.break_activities) {
            state.currentSettings.break_activities.push({
              ...INIT_NEW_HABIT(),
              activity_sequence_id: sequence_id,
              duration_seconds: DEFAULT_DURATION.BREAKING,
              activity_type: ACTIVITY_TYPES.BREAKING
            });
          }
          break;
        case ACTIVITY_TYPES.LIBRARY:
          if (state.activityLibrary) {
            state.activityLibrary.push({
              ...INIT_NEW_HABIT(),
              duration_seconds: DEFAULT_DURATION.DEFAULT,
              category,
              activity_type: ACTIVITY_TYPES.LIBRARY
            });
          }
          break;
        case ACTIVITY_TYPES.CUSTOM_ROUTINES:
          if (customRoutinePosition !== undefined) {
            state.currentSettings.custom_routines = current(
              state.currentSettings?.custom_routines ?? []
            ).map((routine, idx) =>
              idx === customRoutinePosition
                ? {
                    ...routine,
                    standalone_activities: [
                      ...(routine?.standalone_activities ?? []),
                      {
                        ...INIT_NEW_HABIT(),
                        activity_sequence_id: sequence_id,
                        duration_seconds: DEFAULT_DURATION.STANDALONE,
                        activity_type: ACTIVITY_SEQUENCE_TYPE.STANDALONE
                      }
                    ]
                  }
                : routine
            );
          } else {
            state.currentSettings = {
              ...state.currentSettings,
              custom_routines: [
                ...(state.currentSettings.custom_routines ?? []),
                INIT_CUSTOM_ROUTINE()
              ]
            };
          }
          break;
        default:
          if (state.currentSettings.standalone_activities) {
            state.currentSettings.standalone_activities.push({
              ...INIT_NEW_HABIT(),
              activity_sequence_id: sequence_id,
              duration_seconds: DEFAULT_DURATION.STANDALONE,
              activity_type: ACTIVITY_SEQUENCE_TYPE.STANDALONE
            });
          }
      }
      state.isNewHabitAppended = true;
    },
    insertNewHabit: (
      state,
      {
        payload: { type, sequence_id, position, category }
      }: PayloadAction<InsertNewHabitPayload>
    ) => {
      switch (type) {
        case ACTIVITY_TYPES.MORNING:
          if (state.currentSettings.morning_activities) {
            state.currentSettings.morning_activities.splice(
              position,
              SPLICE_INSERT_COUNT,
              {
                ...INIT_NEW_HABIT(),
                activity_sequence_id: sequence_id
              }
            );
          }
          break;
        case ACTIVITY_TYPES.EVENING:
          if (state.currentSettings.evening_activities) {
            state.currentSettings.evening_activities.splice(
              position,
              SPLICE_INSERT_COUNT,
              {
                ...INIT_NEW_HABIT(),
                activity_sequence_id: sequence_id,
                duration_seconds: DEFAULT_DURATION.EVENING
              }
            );
          }
          break;
        case ACTIVITY_TYPES.BREAKING:
          if (state.currentSettings.break_activities) {
            state.currentSettings.break_activities.splice(
              position,
              SPLICE_INSERT_COUNT,
              {
                ...INIT_NEW_HABIT(),
                activity_sequence_id: sequence_id,
                duration_seconds: DEFAULT_DURATION.BREAKING
              }
            );
          }
          break;
        case ACTIVITY_TYPES.LIBRARY:
          if (state.activityLibrary) {
            state.activityLibrary.splice(position, SPLICE_INSERT_COUNT, {
              ...INIT_NEW_HABIT(),
              duration_seconds: DEFAULT_DURATION.LIBARARY,
              category
            });
          }
          break;
        default:
          if (state.currentSettings.standalone_activities) {
            state.currentSettings.standalone_activities.splice(
              position,
              SPLICE_INSERT_COUNT,
              {
                ...INIT_NEW_HABIT(),
                activity_sequence_id: sequence_id,
                duration_seconds: DEFAULT_DURATION.STANDALONE
              }
            );
          }
      }
      state.isNewHabitAppended = false;
    },
    removeHabit: (
      state,
      {
        payload: { type, position, customRoutinePosition }
      }: PayloadAction<{
        type: string;
        position: number;
        customRoutinePosition?: number;
      }>
    ) => {
      if (typeof customRoutinePosition === 'number') {
        state.currentSettings.custom_routines?.[
          customRoutinePosition
        ]?.standalone_activities?.splice(position, SPLICE_DELETE_COUNT);
      } else {
        switch (type) {
          case ACTIVITY_TYPES.MORNING:
            if (state.currentSettings.morning_activities) {
              state.currentSettings.morning_activities.splice(
                position,
                SPLICE_DELETE_COUNT
              );
            }
            break;
          case ACTIVITY_TYPES.EVENING:
            if (state.currentSettings.evening_activities) {
              state.currentSettings.evening_activities.splice(
                position,
                SPLICE_DELETE_COUNT
              );
            }
            break;
          case ACTIVITY_TYPES.BREAKING:
            if (state.currentSettings.break_activities) {
              state.currentSettings.break_activities.splice(
                position,
                SPLICE_DELETE_COUNT
              );
            }
            break;
          case ACTIVITY_TYPES.LIBRARY:
            if (state.activityLibrary) {
              state.activityLibrary.splice(position, SPLICE_DELETE_COUNT);
            }
            break;
          default:
            if (state.currentSettings.standalone_activities) {
              state.currentSettings.standalone_activities.splice(
                position,
                SPLICE_DELETE_COUNT
              );
            }
        }
      }
    },
    cloneHabit: (
      state,
      {
        payload: { templateActivity, newActivityPosition, type }
      }: PayloadAction<{
        templateActivity: ActivityType;
        newActivityPosition: number;
        type: string;
      }>
    ) => {
      switch (type) {
        case ACTIVITY_TYPES.MORNING:
          state.currentSettings.morning_activities?.splice(
            newActivityPosition,
            SPLICE_CLONE_COUNT,
            {
              ...templateActivity,
              id: uuid()
            }
          );
          break;
        case ACTIVITY_TYPES.EVENING:
          state.currentSettings.evening_activities?.splice(
            newActivityPosition,
            SPLICE_CLONE_COUNT,
            {
              ...templateActivity,
              id: uuid()
            }
          );
          break;
        case ACTIVITY_TYPES.BREAKING:
          state.currentSettings.break_activities?.splice(
            newActivityPosition,
            SPLICE_CLONE_COUNT,
            {
              ...templateActivity,
              id: uuid()
            }
          );
          break;
        case ACTIVITY_TYPES.STANDALONE:
          state.currentSettings.standalone_activities?.splice(
            newActivityPosition,
            SPLICE_CLONE_COUNT,
            {
              ...templateActivity,
              id: uuid()
            }
          );
          break;
        case ACTIVITY_TYPES.LIBRARY:
          state.activityLibrary.splice(newActivityPosition, 0, {
            ...templateActivity,
            id: uuid()
          });
      }
    },
    changeChoiceType: (
      state,
      {
        payload: { position, data }
      }: PayloadAction<{ position: number; data: string }>
    ) => {
      if (state.currentSettings.break_activities) {
        state.currentSettings.break_activities[position].choice_type = data;
      }
    },
    changeChoices: (
      state,
      {
        payload: { position, data }
      }: PayloadAction<{ position: number; data: ChoiceType[] }>
    ) => {
      if (state.currentSettings.break_activities) {
        state.currentSettings.break_activities[position].choices = data;
      }
    },
    changeActivityOrder: (
      state,
      {
        payload: { type, data, customRoutinePosition }
      }: PayloadAction<{
        type: string;
        data: ActivityType[];
        customRoutinePosition?: number;
      }>
    ) => {
      switch (type) {
        case ACTIVITY_TYPES.MORNING:
          state.currentSettings.morning_activities = data;
          break;
        case ACTIVITY_TYPES.EVENING:
          state.currentSettings.evening_activities = data;
          break;
        case ACTIVITY_TYPES.BREAKING:
          state.currentSettings.break_activities = data;
          break;
        case ACTIVITY_TYPES.LIBRARY:
          state.activityLibrary = data;
          break;
        case ACTIVITY_TYPES.CUSTOM_ROUTINES:
          if (
            state.currentSettings.custom_routines?.length &&
            typeof customRoutinePosition === 'number'
          ) {
            state.currentSettings.custom_routines[
              customRoutinePosition
            ].standalone_activities = data;
          }

          break;
        default:
          state.currentSettings.standalone_activities = data;
      }
    },
    changePackName: (state, action: PayloadAction<string>) => {
      state.currentSettings.pack_name = action.payload;
    },
    changePackDescription: (state, action: PayloadAction<string>) => {
      state.currentSettings.description = action.payload;
    },
    changePackDescriptionVideo: (state, action: PayloadAction<string>) => {
      state.currentSettings.description_video_url = action.payload;
    },
    changePackWelcomeMessage: (state, action: PayloadAction<string>) => {
      state.currentSettings.welcome_message = action.payload;
    },
    changePackWelcomeMessageVideo: (state, action: PayloadAction<string>) => {
      state.currentSettings.welcome_video_url = action.payload;
    },
    changePackCreatorName: (state, action: PayloadAction<string>) => {
      state.currentSettings.creator_name = action.payload;
    },
    changePackIsFeaturedStatus: (state, action: PayloadAction<boolean>) => {
      state.currentSettings.is_featured = action.payload;
    },
    changeIsTextEditorWithinCharacterLimit: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isTextEditorWithinCharacterLimit = action.payload;
    },
    changePackFeaturedForOnboardingStatus: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.currentSettings.featured_for_onboarding = action.payload;
    },
    changePackMarketplaceApprovalStatus: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.currentSettings.marketplace_approval_status = action.payload;
    },
    updatePlatform: (state, { payload }: PayloadAction<string | undefined>) => {
      if (payload) {
        state.platform = payload;
      }
    },
    setGeneratorMode: (state, { payload }: PayloadAction<Mode>) => {
      state.mode = payload;
    },
    addInstalledApps: (
      state,
      { payload }: PayloadAction<{ installedApps: string[] }>
    ) => {
      state.suggestions = payload?.installedApps ?? [];
    },
    configureTabs: (
      state,
      {
        payload: { mode, routine: customRoutine }
      }: PayloadAction<{ mode: string; routine?: string }>
    ) => {
      switch (mode) {
        case Mode.ROUTINE:
          state.tabs = TABS.ROUTINE;
          break;
        case Mode.STANDALONE:
          state.tabs = TABS.STANDALONE;
          break;
        case Mode.FREESTYLE:
          state.tabs = TABS.FREESTYLE;
          break;
        case Mode.FOCUS_MODE:
          state.tabs = TABS.FOCUS_MODE;
          break;
        case Mode.CUSTOM:
          {
            let tabs = TABS.CUSTOM_ROUTINE;
            if (customRoutine === ROUTINE.MORNING) {
              tabs = [TAB.MORNING];
            } else if (customRoutine === ROUTINE.BREAKING) {
              tabs = [TAB.MICRO_BREAKS];
            } else if (customRoutine === ROUTINE.EVENING) {
              tabs = [TAB.EVENING];
            } else if (customRoutine === ROUTINE.TIMING) {
              tabs = [TAB.TIMING];
            } else if (customRoutine === ROUTINE.SUGGESTION) {
              //@Description: applied when both routines selected in routine suggestions embedded UI
              const shouldSelectBothRoutines =
                state.routineSuggestionPayload.routines.every(
                  (routine) => routine.value
                );
              const { morning_activities, evening_activities } =
                getRoutineActivitiesFromSuggestions(
                  state.routineSuggestions ?? []
                );
              const sorted_morning_activities = morning_activities.sort(
                (activityA, activityB) =>
                  activityA.duration_seconds - activityB.duration_seconds
              );
              const sorted_evening_activities = evening_activities.sort(
                (activityA, activityB) =>
                  activityA.duration_seconds - activityB.duration_seconds
              );

              if (shouldSelectBothRoutines) {
                state.currentSettings.morning_activities =
                  sorted_morning_activities;
                state.currentSettings.evening_activities =
                  sorted_evening_activities;
                tabs = [TAB.MORNING, TAB.EVENING];
              } else {
                state.activeTabIndex = 0; //@Description: reset tab index as there's only one tab
                const routine = getRoutineValue(
                  state.routineSuggestionPayload.routines
                );
                if (routine === ROUTINE.MORNING) {
                  state.currentSettings.morning_activities =
                    sorted_morning_activities;
                  state.currentSettings.evening_activities = [];
                  tabs = [TAB.MORNING];
                } else {
                  state.currentSettings.morning_activities = [];
                  state.currentSettings.evening_activities =
                    sorted_evening_activities;
                  tabs = [TAB.EVENING];
                }
              }
            }
            state.mode = mode;
            state.tabs = tabs;
            state.customRoutine = customRoutine ?? EMPTY_STRING;
          }
          break;
        default:
          state.tabs = TABS.DEFAULT;
      }
    },
    changeIsEmbeddedWebViewLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isEmbeddedWebViewLoading = payload;
    },
    updateError: (
      state,
      {
        payload
      }: PayloadAction<{
        message: string;
        status?: number;
        url?: string;
      }>
    ) => {
      state.error = payload;
    },
    updateRoutineCategory: (
      state,
      {
        payload: { type, position, destination_type, destination_position }
      }: PayloadAction<{
        type: string;
        position: number;
        destination_type: string;
        destination_position: string;
      }>
    ) => {
      let sourceRoutineActivities = getActivitiesOfSettings(type, state);
      let destinationRoutineActivities = getActivitiesOfSettings(
        destination_type,
        state
      );
      const habit = sourceRoutineActivities[position];
      sourceRoutineActivities = sourceRoutineActivities.filter(
        (_: ActivityType, idx: number) => position !== idx
      );
      const movingHabit = {
        ...habit,
        choice_type: undefined,
        choices: []
      };
      switch (destination_position) {
        case ROUTINE_DESTINATION_POSITION.START:
          destinationRoutineActivities = [
            movingHabit,
            ...destinationRoutineActivities
          ];
          break;
        case ROUTINE_DESTINATION_POSITION.END:
          destinationRoutineActivities = [
            ...destinationRoutineActivities,
            movingHabit
          ];
          break;
        default:
          destinationRoutineActivities.splice(
            Math.floor(destinationRoutineActivities.length / 2),
            NUMBERS.ZERO,
            movingHabit
          );
      }
      state.isShowMoreOptionsOpened = false;
      state.currentSettings = {
        ...state.currentSettings,
        [type]: sourceRoutineActivities,
        [destination_type]: destinationRoutineActivities
      };
    },
    updateMoreOptions: (
      state,
      { payload }: PayloadAction<{ type: string; position: number }>
    ) => {
      state.moreOptions = payload;
    },
    updateIsShowMoreOptionsOpened: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isShowMoreOptionsOpened = payload;
    },
    updateIsTokenAddedToInterceptor: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isTokenAddedToInterceptor = payload;
    },
    updateCutoffTimeForNonHighPriority: (state, { payload }) => {
      if (payload) {
        state.currentSettings.cutoff_time_for_non_high_priority_activities =
          payload;
      } else {
        const { cutoff_time_for_non_high_priority_activities, ...rest } =
          state.currentSettings;
        state.currentSettings = rest;
      }
    },
    changeAreLibraryActivitiesLoading: (state, action) => {
      state.areLibraryActivitiesLoading = action.payload;
    },
    updateActiveHabitPackFocusTemplatesTab: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.activeHabitPackFocusTemplatesTab = payload;
    },
    updateRoutinePlayingActivity: (
      state,
      {
        payload: { type, activity }
      }: PayloadAction<{ type: string; activity: ActivityType | null }>
    ) => {
      if (type === ROUTINE_PLAYING_TYPE.CURRENT) {
        state.currentPlayingActivity = activity;
      } else {
        state.nextPlayingActivity = activity;
      }
    },
    updateRoutinePlayerActivities: (
      state,
      {
        payload: { type, activities }
      }: PayloadAction<{ type: string; activities: ActivityType[] }>
    ) => {
      state.routinePlayerActivities = { type, activities };
    },
    updateIsRoutineSummaryFetching: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isRoutineSummaryFetching = payload;
    },
    updateRoutineSummary: (
      state,
      { payload }: PayloadAction<{ date: string; summary: string }[]>
    ) => {
      state.routineSummary = payload;
    },
    updateIsDeviceRegistering: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeviceRegistering = payload;
    },
    updateIsSavingRoutineCompletedActivity: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSavingRoutineCompletedActivity = payload;
    },
    updateIsShowRoutineModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowRoutineModal = payload;
    },
    duplicateMorningOnEveningRoutine: (state) => {
      const activity_sequence_id: string = uuid();
      const activities = state.currentSettings.morning_activities?.map(
        (activity: ActivityType) => {
          let log_quantity_questions: LogQuantityQuestion[] = [];
          if (activity.log_quantity_questions?.length) {
            log_quantity_questions = activity.log_quantity_questions.map(
              (question) => ({
                ...question,
                linked_question_id: question.id
              })
            );
          }
          return {
            ...activity,
            activity_sequence_id,
            linked_activity_template_id: activity.id,
            log_quantity_questions
          };
        }
      );
      if (activities) {
        state.currentSettings.evening_activities = [...activities];
        state.isShowRoutineModal = false;
      }
    },
    createRandomActivityWithChoiceOfMorningRoutine: (
      state,
      {
        payload: { activityName, choices }
      }: PayloadAction<{ activityName: string; choices: ChoiceType[] }>
    ) => {
      const new_activity_sequence_id: string = uuid();
      let activity_sequence_id: string = new_activity_sequence_id;
      const {
        currentSettings: { break_activities }
      } = current(state);
      if (break_activities && break_activities?.length > 0) {
        activity_sequence_id =
          break_activities[0].activity_sequence_id ?? new_activity_sequence_id;
      }
      const updatedChoices = choices.map((activity) => ({
        ...activity,
        activity_sequence_id
      }));
      const randomActivity = {
        ...INIT_NEW_HABIT(),
        name: activityName,
        duration_seconds: DEFAULT_DURATION.BREAKING,
        choices: [...updatedChoices]
      };
      state.currentSettings.break_activities?.push(randomActivity);
      state.isShowRoutineModal = false;
    },
    updateIsFetchingNextRoutineActivity: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingNextRoutineActivity = payload;
    },
    updateActiveTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.activeTabIndex = payload;
    },
    updateIsSettingEditingFinished: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSettingEditingFinished = payload;
    },
    updateAreSettingsValid: (state, { payload }: PayloadAction<boolean>) => {
      state.areSettingsValid = payload;
    },
    updateActiveMarketTab: (state, { payload }: PayloadAction<number>) => {
      state.activeMarketTab = payload;
    },
    updateAreSettingsSaving: (state, { payload }: PayloadAction<boolean>) => {
      state.areSettingsSaving = payload;
    },
    resetActiveTabIndex: (state) => {
      state.activeTabIndex = NUMBERS.ZERO;
    },
    updateLanguage: (state, { payload }: PayloadAction<string | undefined>) => {
      if (payload) {
        state.language = payload;
      }
    },
    updateIsUnlockHabitPackModalShown: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUnlockHabitPackModalShown = payload;
    },
    unlockCurrentSettings: (
      state,
      {
        payload: { morningActivities, breakingActivities, eveningActivities }
      }: PayloadAction<{
        morningActivities: ActivityType[];
        breakingActivities: ActivityType[];
        eveningActivities: ActivityType[];
      }>
    ) => {
      state.currentSettings.morning_activities = morningActivities;
      state.currentSettings.break_activities = breakingActivities;
      state.currentSettings.evening_activities = eveningActivities;
      state.isUnlockHabitPackModalShown = false;
    },
    copyActivity: (
      state,
      {
        payload: { activity, position, type }
      }: PayloadAction<{
        activity: ActivityType;
        position: number;
        type: string;
      }>
    ) => {
      switch (type) {
        case ACTIVITY_TYPES.MORNING:
          state.currentSettings.morning_activities?.splice(
            position,
            SPLICE_COPY_COUNT,
            {
              ...activity,
              id: uuid()
            }
          );
          break;
        case ACTIVITY_TYPES.EVENING:
          state.currentSettings.evening_activities?.splice(
            position,
            SPLICE_COPY_COUNT,
            {
              ...activity,
              id: uuid()
            }
          );
          break;
        case ACTIVITY_TYPES.BREAKING:
          state.currentSettings.break_activities?.splice(
            position,
            SPLICE_COPY_COUNT,
            {
              ...activity,
              id: uuid()
            }
          );
          break;
        case ACTIVITY_TYPES.STANDALONE:
          state.currentSettings.standalone_activities?.splice(
            position,
            SPLICE_COPY_COUNT,
            {
              ...activity,
              id: uuid()
            }
          );
      }
      state.isShowMoreOptionsOpened = false;
    },
    updateShowPrivacyPolicyModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showPrivacyPolicyModal = payload;
    },
    updateAiTone: (
      state,
      { payload }: PayloadAction<AiToneOptions | undefined>
    ) => {
      if (payload) {
        state.aiTone = payload;
      } else {
        'aiTone' in state && delete state.aiTone;
      }
    },
    updateShowSkipActivityModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showSkipActivityModal = payload;
    },
    updateRoutinePlayerStep: (state, { payload }: PayloadAction<number>) => {
      state.routinePlayerStep = payload;
    },
    updateIsLeaderBoardFetching: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLeaderBoardFetching = payload;
    },
    updateIsRequestDataConfirmationModalShown: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isRequestDataConfirmationModalShown = payload;
    },
    updateIsRequestDataProcessing: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isRequestDataProcessing = payload;
    },
    updateIsAccountDeleteConfirmationModalShown: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAccountDeleteConfirmationModalShown = payload;
    },
    updateIsAccountDeleting: (state, { payload }: PayloadAction<boolean>) => {
      state.isAccountDeleting = payload;
    },
    updateIsAccountDeleted: (state, { payload }: PayloadAction<boolean>) => {
      state.isAccountDeleted = payload;
    },
    updateIsUserSettingsModified: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUserSettingsModified = payload;
    },
    updateThemeMode: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      if (payload) {
        state.themeMode = payload as THEME_OPTION;
      }
    },
    updateFont: (state, { payload }: PayloadAction<string | undefined>) => {
      if (payload) {
        state.font = payload as FONT_OPTION;
      }
    },
    updateIsActivityLibraryUpdating: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isActivityLibraryUpdating = payload;
    },
    updatePreviousActivityLibrary: (
      state,
      { payload }: PayloadAction<ActivityType[]>
    ) => {
      state.previousActivityLibrary = payload;
    },
    updateFlags: (state, { payload }: PayloadAction<FLAG[]>) => {
      state.flags = payload;
    },
    resetFlags: (state) => {
      state.flags = [];
    },
    updateIsPlatformCoursesSyncing: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isPlatformCoursesSyncing = payload;
    },
    updateValidationErrors: (
      state,
      { payload }: PayloadAction<ValidationError[]>
    ) => {
      state.validationErrors = payload;
    },
    updateFeedback: (state, { payload }: PayloadAction<FeedbackType>) => {
      state.feedback = payload;
    },
    updateIsFetchingRoutineSuggestions: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingRoutineSuggestions = payload;
    },
    updateRoutineSuggestions: (
      state,
      { payload }: PayloadAction<ActivityType[] | null>
    ) => {
      state.routineSuggestions = payload;
    },
    updateRoutineSuggestionPayload: (
      state,
      { payload }: PayloadAction<RoutineSuggestionPayload>
    ) => {
      state.routineSuggestionPayload = payload;
    },
    updateAreSuggestedHabitPacksInstalled: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.areSuggestedHabitPacksInstalled = payload;
    },
    updateIsInstallingRoutineSuggestions: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isInstallingRoutineSuggestions = payload;
    },
    updateShowEditSuggestionsModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showEditSuggestionsModal = payload;
    },
    updateIsUserOnboarding: (state, { payload }: PayloadAction<boolean>) => {
      state.isUserOnboarding = payload;
    },
    updateCustomRoutines: (
      state,
      { payload }: PayloadAction<CustomRoutineType[]>
    ) => {
      state.currentSettings.custom_routines = payload;
    },
    updateCustomRoutine: (
      state,
      {
        payload: { feature, position, value }
      }: PayloadAction<{
        feature: string;
        position: number;
        value: string | string[] | ActivityType[] | CUSTOM_ROUTINE_TRIGGER;
      }>
    ) => {
      const customRoutines =
        state.currentSettings.custom_routines?.slice() ?? [];
      switch (feature) {
        case CUSTOM_ROUTINE_FEATURE.NAME:
          customRoutines[position].name = value as string;
          break;
        case CUSTOM_ROUTINE_FEATURE.START_TIME:
          customRoutines[position].start_time = value as string;
          break;
        case CUSTOM_ROUTINE_FEATURE.END_TIME:
          customRoutines[position].end_time = value as string;
          break;
        case CUSTOM_ROUTINE_FEATURE.TRIGGER:
          customRoutines[position].trigger = value as CUSTOM_ROUTINE_TRIGGER;
          break;
        case CUSTOM_ROUTINE_FEATURE.DAYS:
          customRoutines[position].days_of_week = value as string[];
          break;
        default:
          customRoutines[position].standalone_activities =
            value as ActivityType[];
      }
      state.currentSettings.custom_routines = [...customRoutines];
    },
    updateDeviceID: (state, { payload }: PayloadAction<string | undefined>) => {
      state.device_id = payload;
    },
    updateRoutineTimeValidation: (
      state,
      { payload }: PayloadAction<ROUTINE_TIME_VALIDATION>
    ) => {
      state.routineTimeValidation = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initUserSettings.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length) {
          if (USER_SETTINGS_KEYS.HAS_EDITED_SETTINGS in payload) {
            delete payload.has_edited_settings;
          }
          if (
            !(
              USER_SETTINGS_KEYS.CUTOFF_TIME_FOR_NON_HIGH_PRIORITY_ACTIVITIES in
              payload
            )
          ) {
            payload = {
              ...payload,
              cutoff_time_for_non_high_priority_activities: null
            };
          }
          state.currentSettings = payload;
          state.previousSettings = payload;
        }
        state.isSettingLoaded = true;
        state.isSettingLoading = false;
        return state;
      })
      .addCase(initUserSettings.rejected, (state) => {
        state.isSettingLoaded = false;
      })
      .addCase(saveUserSettings.fulfilled, (state) => {
        state.areSettingsSaving = false;
      })
      .addCase(saveUserSettings.rejected, (state) => {
        state.areSettingsSaving = false;
      })
      .addCase(getUserSettings.fulfilled, (state, { payload }) => {
        if (payload && Object.keys(payload).length) {
          if (USER_SETTINGS_KEYS.HAS_EDITED_SETTINGS in payload) {
            delete payload.has_edited_settings;
          }
          if (
            !(
              USER_SETTINGS_KEYS.CUTOFF_TIME_FOR_NON_HIGH_PRIORITY_ACTIVITIES in
              payload
            )
          ) {
            payload = {
              ...payload,
              cutoff_time_for_non_high_priority_activities: null
            };
          }
          const [morning_activities, break_activities] =
            filterOutCutoffTimeAndTutorial(
              [...(payload?.morning_activities ?? [])],
              [...(payload?.break_activities ?? [])]
            );
          const evening_activities = [
            ...(payload?.evening_activities ?? [])
          ].map(({ tutorial, ...rest }) => {
            return { ...rest, tutorial: tutorial?.id ?? tutorial };
          });
          state.currentSettings = {
            ...payload,
            morning_activities,
            break_activities,
            evening_activities
          };
          state.previousSettings = {
            ...payload,
            morning_activities,
            break_activities,
            evening_activities
          };
        }
        state.isSettingLoaded = true;
        state.isSettingLoading = false;
      })
      .addCase(getUserSettings.rejected, (state) => {
        state.isSettingLoading = false;
      })
      .addCase(validatePastedSettings.fulfilled, (state) => {
        state.loadingSchema = false;
      })
      .addCase(validatePastedSettings.rejected, (state) => {
        state.loadingSchema = false;
      })
      .addCase(getLibraryActivities.fulfilled, (state, { payload }) => {
        state.activityLibrary = payload;
        state.previousActivityLibrary = payload;
        state.areLibraryActivitiesLoading = false;
      })
      .addCase(getLibraryActivities.rejected, (state) => {
        state.areLibraryActivitiesLoading = false;
      })
      .addCase(getRoutineSummary.fulfilled, (state, { payload }) => {
        state.routineSummary = payload;
        state.isRoutineSummaryFetching = false;
      })
      .addCase(getRoutineSummary.rejected, (state) => {
        state.isRoutineSummaryFetching = false;
      })
      .addCase(getUserStatsLeaderBoard.fulfilled, (state, { payload }) => {
        if (payload) {
          state.leaders = payload;
        }
        state.isLeaderBoardFetching = false;
      })
      .addCase(getUserStatsLeaderBoard.rejected, (state) => {
        state.isLeaderBoardFetching = false;
      })
      .addCase(
        getRoutineSuggestionActivities.fulfilled,
        (state, { payload }) => {
          state.routineSuggestions = payload;
          state.isFetchingRoutineSuggestions = false;
        }
      )
      .addCase(getRoutineSuggestionActivities.rejected, (state) => {
        state.routineSuggestions = [];
      });
  }
});

export const {
  updateShutdownTime,
  updateStartupTime,
  updateSettingFeatures,
  changeBreakingInterval,
  changeIncludeEveryBreak,
  changeIsOfficeFriendly,
  removeHabit,
  newHabit,
  changeChoiceType,
  changeChoices,
  changeActivityOrder,
  changePackName,
  changePackDescription,
  changePackDescriptionVideo,
  changePackWelcomeMessage,
  changePackWelcomeMessageVideo,
  insertNewHabit,
  updatePreviousSettings,
  changeCurrentSettings,
  changeIsSettingLoading,
  changeIsSettingLoaded,
  setGeneratorMode,
  updatePlatform,
  addInstalledApps,
  changeIsValidateSettings,
  changeLoadingSchema,
  changeShowChoice,
  changeShowTabs,
  changeShowFreeStyle,
  changeShowDropProcessor,
  configureTabs,
  changeIsEmbeddedWebViewLoading,
  updateError,
  updateRoutineCategory,
  updateMoreOptions,
  updateIsShowMoreOptionsOpened,
  updateIsTokenAddedToInterceptor,
  changePackCreatorName,
  updateCutoffTimeForNonHighPriority,
  changeAreLibraryActivitiesLoading,
  cloneHabit,
  updateActiveHabitPackFocusTemplatesTab,
  updateRoutinePlayingActivity,
  updateRoutinePlayerActivities,
  updateIsRoutineSummaryFetching,
  updateRoutineSummary,
  updateIsDeviceRegistering,
  updateIsSavingRoutineCompletedActivity,
  updateIsShowRoutineModal,
  duplicateMorningOnEveningRoutine,
  createRandomActivityWithChoiceOfMorningRoutine,
  updateActivityFromMoreOptions,
  changePackIsFeaturedStatus,
  changePackFeaturedForOnboardingStatus,
  changePackMarketplaceApprovalStatus,
  updateIsFetchingNextRoutineActivity,
  changeIsTextEditorWithinCharacterLimit,
  updateActiveTabIndex,
  updateIsSettingEditingFinished,
  updateAreSettingsValid,
  updateActiveMarketTab,
  updateAreSettingsSaving,
  resetActiveTabIndex,
  updateLanguage,
  updateIsUnlockHabitPackModalShown,
  unlockCurrentSettings,
  copyActivity,
  updateShowPrivacyPolicyModal,
  updateAiTone,
  updateShowSkipActivityModal,
  updateRoutinePlayerStep,
  updateIsLeaderBoardFetching,
  updateIsRequestDataConfirmationModalShown,
  updateIsRequestDataProcessing,
  updateIsAccountDeleteConfirmationModalShown,
  updateIsAccountDeleting,
  updateIsAccountDeleted,
  updateIsUserSettingsModified,
  updateThemeMode,
  updateFont,
  updateIsActivityLibraryUpdating,
  updatePreviousActivityLibrary,
  updateFlags,
  resetFlags,
  updateIsPlatformCoursesSyncing,
  updateValidationErrors,
  updateFeedback,
  updateIsFetchingRoutineSuggestions,
  updateRoutineSuggestions,
  updateRoutineSuggestionPayload,
  updateAreSuggestedHabitPacksInstalled,
  updateIsInstallingRoutineSuggestions,
  updateShowEditSuggestionsModal,
  updateIsUserOnboarding,
  updateCustomRoutines,
  updateCustomRoutine,
  updateDeviceID,
  updateRoutineTimeValidation
} = setting.actions;

export default setting.reducer;
