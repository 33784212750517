import PlusCircle from 'assets/icons/PlusCircle';
import classNames from 'classnames';
import COLOR from 'constants/color';
import { ATTRIB } from 'constants/test';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addNewActivityButtonSelector } from 'store/reducer/setting/selectors';
import { newHabit } from 'store/reducer/setting/slice';
import { getNewHabitProps } from 'utils/support';
import { isDarkModeActivated } from 'utils/validation';
import postHog from 'posthog-js';

const AddNewActivityButton = () => {
  const dispatch = useAppDispatch();
  const {
    themeMode,
    tabs,
    activeTabIndex,
    customRoutine,
    currentSettings,
    routineSuggestionsSelectedRoutines
  } = useAppSelector(addNewActivityButtonSelector);
  const shouldActivateDarkMode = useMemo(
    () => isDarkModeActivated(themeMode),
    [themeMode]
  );

  const [activity_type, sequence_id] = getNewHabitProps(
    tabs,
    activeTabIndex,
    currentSettings,
    customRoutine,
    routineSuggestionsSelectedRoutines
  );

  return (
    <button
      data-test={ATTRIB.TEST.ICON_ADD_HABIT}
      data-locator={ATTRIB.LOCATOR.ADD_HABIT}
      id={ATTRIB.TEST.ICON_ADD_HABIT}
      onClick={() => {
        dispatch(
          newHabit({
            type: activity_type,
            sequence_id
          })
        );
        postHog.capture('add-new-habit', {
          'routine-type': activity_type
        });
      }}
      className={classNames(
        'group relative flex flex-col items-center rounded-full w-fit h-fit my-1.5',
        {
          'bg-gray-600': shouldActivateDarkMode,
          'bg-white': !shouldActivateDarkMode
        }
      )}
    >
      <PlusCircle styles='~w-5/6 ~h-5/6' fill={COLOR.BLACK} />

      <span
        className={`min-w-max absolute top-1/2 -translate-y-1/2 right-8 hidden group-hover:flex text-xs xl:text-sm bg-gray-700 rounded ${
          !shouldActivateDarkMode && 'text-white'
        } z-50 px-1.5 py-0.5`}
      >
        {t('new_activity')}
      </span>
    </button>
  );
};

export default AddNewActivityButton;
