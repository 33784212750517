import { PropsWithChildren } from 'react';

const TabBodyWrapper = ({
  children,
  styles
}: PropsWithChildren<{ styles?: string }>) => (
  <div className={styles ?? 'w-full h-full flex flex-col items-center gap-2.5'}>
    {children}
  </div>
);

export default TabBodyWrapper;
