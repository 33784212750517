import {
  CUSTOM_ROUTINE_TRIGGER,
  DaysOfWeek,
  INVALID_FEATURE,
  Mode,
  RoutineSuggestionEditHabit,
  SUGGESTED_PACK,
  THEME_OPTION
} from 'constants/enum';
import {
  DEFAULT_HABIT_DURATION,
  DEV_CUSTOM_ROUTINE_USER_ID,
  EMPTY_STRING,
  MAXIMUM_ALLOWED_SECONDS,
  PLATFORMS,
  PRIORITY_OPTION,
  TAB,
  TIME_FORMAT_24H
} from 'constants/general';
import {
  ActivityType,
  ActivityTypeWithPackTitle,
  FocusModeTemplateType,
  HabitPackType
} from 'interfaces/commonInterface';
import moment from 'moment';
import { FocusMode } from 'interfaces/userInterface';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import {
  CustomRoutineType,
  SettingsType,
  ValidationError
} from 'interfaces/settingInterface';
import { isYoutubeURL } from './validation';
import AlarmClock from 'assets/icons/AlarmClock';
import SunSeason from 'assets/icons/SunSeason';
import HotBeverage from 'assets/icons/HotBeverage';
import Moon from 'assets/icons/Moon';
import ShoppingBags from 'assets/icons/ShoppingBags';
import { v4 as uuid } from 'uuid';
import Customize from 'assets/icons/Customize';
import { ROUTES } from 'constants/routes';
import { DropDownSelectedValue } from 'interfaces';
import { WEEK_DAYS, WEEKEND } from 'assets/data';
import { MultiValue } from 'react-select';
import { store } from 'store';

export const getHabitPackActivitiesWithPackTitle = (packs: HabitPackType[]) =>
  packs
    .map((pack) => [
      ...addHabitPackTitleToActivity(
        pack.morning_activities ?? [],
        pack.pack_name
      ),
      ...addHabitPackTitleToActivity(
        pack.break_activities ?? [],
        pack.pack_name
      ),
      ...addHabitPackTitleToActivity(
        pack.evening_activities ?? [],
        pack.pack_name
      )
    ])
    .flat();

export const addHabitPackTitleToActivity = (
  activities: ActivityType[],
  pack_title: string
) => activities.map((activity) => ({ ...activity, pack_title }));

export const getMatchedActivities = (
  marketplaceActivities: ActivityTypeWithPackTitle[],
  activityLibrary: ActivityType[],
  search: string,
  userInput: string
) => {
  const updateSuggestions = [
    ...marketplaceActivities
      .filter((activity) =>
        activity.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      .sort(
        (activityA, activityB) =>
          activityA.duration_seconds - activityB.duration_seconds
      )
      .map((activity) => ({
        where: SUGGESTED_PACK.HABIT_PACK,
        name: activity.name,
        duration: activity.duration_seconds,
        pack_title: activity.pack_title ?? EMPTY_STRING
      })),
    ...(activityLibrary ?? [])
      .filter((activity) =>
        activity.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      .sort(
        (activityA, activityB) =>
          activityA.duration_seconds - activityB.duration_seconds
      )
      .map((activity) => ({
        where: SUGGESTED_PACK.LIBRARY,
        name: activity.name,
        duration: activity.duration_seconds
      }))
  ];
  return updateSuggestions?.length
    ? [
        {
          duration: DEFAULT_HABIT_DURATION,
          name: userInput ?? EMPTY_STRING,
          where: SUGGESTED_PACK.CREATE_NEW_HABIT
        },
        ...updateSuggestions
      ]
    : [];
};

export const formatTimeTo12Hour = (time?: string, seconds?: number) =>
  moment(time, TIME_FORMAT_24H).isValid()
    ? moment(time, TIME_FORMAT_24H)
        .add(seconds ?? 0, 'seconds')
        .format('hh:mm a')
    : EMPTY_STRING;

export const getHighPriorityRoutineDurationAsSecond = (
  activities?: ActivityType[]
) =>
  (activities ?? []).reduce(
    (duration, activity) =>
      (activity.priority ?? PRIORITY_OPTION.HIGH) === PRIORITY_OPTION.HIGH &&
      !activity.show_saved_distracting_websites
        ? duration + activity.duration_seconds
        : duration,
    0
  );

export const getAllTypesOfRoutineDurationAsSecond = (
  activities?: ActivityType[]
) =>
  (activities ?? []).reduce(
    (duration, activity) => duration + (activity.duration_seconds ?? 0),
    0
  );

export const addMinutesToTime = (time: string, minutes: number): string => {
  const [hours, mins] = time.split(':').map(Number);
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(mins + minutes);
  const newHours = date.getHours();
  const newMinutes = date.getMinutes();
  return `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
};

export const convertSecondsToMinutes = (seconds: number) => {
  return Math.floor(seconds / 60);
};

export const checkActivityDurationAgainstRelaxFocusModeDuration = (
  inputDuration: number,
  user_focus_modes: FocusMode[],
  cutoff_time: string,
  shutdown_time?: string,
  evening_activities?: ActivityType[],
  currentActivity?: ActivityType
) => {
  const evening_activity = evening_activities?.find(
    (activity) => activity.id === currentActivity?.id
  );
  const isRelaxFocusModeSelected = user_focus_modes.some(
    (focus_mode) =>
      (focus_mode?.name?.includes(t('relax')) ||
        focus_mode?.metadata?.isRelaxFocus) &&
      currentActivity?.allowed_focus_mode_id === focus_mode?.id
  );
  const isGuiltyFreePopUpEnabled =
    currentActivity?.show_saved_distracting_websites;
  if (
    cutoff_time &&
    evening_activity &&
    isRelaxFocusModeSelected &&
    isGuiltyFreePopUpEnabled
  ) {
    const relaxFocusModeDuration = moment(cutoff_time, TIME_FORMAT_24H).diff(
      moment(shutdown_time, TIME_FORMAT_24H),
      'seconds'
    );
    inputDuration > relaxFocusModeDuration &&
      toast.warn(
        t('relax_mode_habit_duration_warning', {
          cutoff_time: moment(cutoff_time, TIME_FORMAT_24H).format('hh:mm a'),
          max_duration: moment.duration(inputDuration, 'seconds').asMinutes()
        }),
        {
          pauseOnHover: true
        }
      );
  }
};

export const getSettingsValidationErrors = (
  settings: SettingsType | null,
  mode: Mode,
  currentViewedFocusTemplate: FocusModeTemplateType | null
) => {
  const errors: ValidationError[] = [];
  if (currentViewedFocusTemplate) {
    !currentViewedFocusTemplate?.name &&
      errors.push({
        tab: { title: TAB.ACTIVITY },
        feature: INVALID_FEATURE.PACK_NAME,
        message: t('validation.empty_content'),
        invalid_value: t('validation.empty_content')
      });

    !isYoutubeURL(currentViewedFocusTemplate?.description_video_url ?? '') &&
      errors.push({
        tab: { title: TAB.PACK_INFO },
        feature: INVALID_FEATURE.PACK_DESCRIPTION_URL,
        message: t('validation.invalid_url'),
        invalid_value: currentViewedFocusTemplate?.description_video_url
      });

    !isYoutubeURL(currentViewedFocusTemplate?.welcome_video_url ?? '') &&
      errors.push({
        tab: { title: TAB.PACK_INFO },
        feature: INVALID_FEATURE.PACK_WELCOME_URL,
        message: t('validation.invalid_url'),
        invalid_value: currentViewedFocusTemplate?.welcome_video_url
      });

    !currentViewedFocusTemplate?.allowed_urls?.length &&
      errors.push({
        tab: { title: TAB.PACK_INFO },
        feature: INVALID_FEATURE.ALLOWED_URLS,
        message: t('validation.invalid_url')
      });

    !currentViewedFocusTemplate?.allowed_apps?.length &&
      errors.push({
        tab: { title: TAB.PACK_INFO },
        feature: INVALID_FEATURE.ALLOWED_APPS,
        message: t('validation.empty_allowed_apps')
      });
  } else {
    const isErrorNotAdded = (feature: INVALID_FEATURE) =>
      errors.every((error) => error.feature !== feature);

    if (mode === Mode.DEFAULT) {
      !settings?.break_after_minutes &&
        isErrorNotAdded(INVALID_FEATURE.BREAK_FREQUENCY) &&
        errors.push({
          tab: { title: TAB.TIMING },
          message: t('validation.invalid_value'),
          feature: INVALID_FEATURE.BREAK_FREQUENCY,
          invalid_value: t('validation.empty_content')
        });

      !settings?.startup_time &&
        isErrorNotAdded(INVALID_FEATURE.START_UP) &&
        errors.push({
          tab: { title: TAB.TIMING },
          message: t('validation.invalid_time'),
          feature: INVALID_FEATURE.START_UP,
          invalid_value: t('validation.empty_content')
        });

      !settings?.shutdown_time &&
        isErrorNotAdded(INVALID_FEATURE.SHUTDOWN) &&
        errors.push({
          tab: { title: TAB.TIMING },
          message: t('validation.invalid_time'),
          feature: INVALID_FEATURE.SHUTDOWN,
          invalid_value: t('validation.empty_content')
        });
    } else if ([Mode.ROUTINE, Mode.STANDALONE].includes(mode)) {
      !settings?.pack_name &&
        errors.push({
          tab: { title: TAB.PACK_INFO },
          feature: INVALID_FEATURE.PACK_NAME,
          message: t('validation.empty_content'),
          invalid_value: t('validation.empty_content')
        });

      !isYoutubeURL(settings?.description_video_url ?? '') &&
        errors.push({
          tab: { title: TAB.PACK_INFO },
          feature: INVALID_FEATURE.PACK_DESCRIPTION_URL,
          message: t('validation.invalid_url'),
          invalid_value: settings?.description_video_url
        });
    }
  }
  return errors;
};

export const filterRoutinesInvalidActivities = (
  morning: ActivityType[],
  micro_breaks: ActivityType[],
  evening: ActivityType[],
  customRoutines?: CustomRoutineType[]
) => {
  const morning_activities = removeRoutineInvalidActivities(morning ?? []);
  const break_activities = removeRoutineInvalidActivities(micro_breaks ?? []);
  const evening_activities = removeRoutineInvalidActivities(evening ?? []);
  const custom_routines = customRoutines
    ?.filter((routine) =>
      routine.name && routine.trigger === CUSTOM_ROUTINE_TRIGGER.ON_SCHEDULE
        ? routine.start_time && routine.end_time && routine.days_of_week?.length
        : true
    )
    .map((routine) => ({
      ...routine,
      standalone_activities: removeRoutineInvalidActivities(
        routine.standalone_activities ?? []
      )
    }));
  return {
    morning_activities,
    break_activities,
    evening_activities,
    custom_routines
  };
};

const removeRoutineInvalidActivities = (activities: ActivityType[]) =>
  activities.filter(
    (activity) =>
      activity.name &&
      activity.duration_seconds &&
      (activity.completion_requirements === ''
        ? activity.completion_requirements
        : activity.duration_seconds <= MAXIMUM_ALLOWED_SECONDS)
  );

export const getSettingTabIcon = (tabTitle: string) => {
  switch (tabTitle) {
    case TAB.TIMING:
      return <AlarmClock />;
    case TAB.MORNING:
      return <SunSeason />;
    case TAB.MICRO_BREAKS:
      return <HotBeverage />;
    case TAB.EVENING:
      return <Moon />;
    case TAB.HABIT_PACK:
      return <ShoppingBags />;
    default:
      return <Customize />;
  }
};

//@Description, break temporary disabled for mobile apps
//@Description: custom routines temporary disabled for desktop and mobile apps
export const getRoutineTabs = (
  tabs: string[],
  platform: string,
  isGeekMode: boolean,
  isUserAdmin: boolean,
  customRoutine?: string,
  userId?: string
) => {
  let routineTabs: string[] = [];
  if (!customRoutine && PLATFORMS.WEB !== platform) {
    if (!isGeekMode && isMobilePlatform()) {
      routineTabs = tabs.filter(
        (tab) => ![TAB.MICRO_BREAKS, TAB.CUSTOM_ROUTINES].includes(tab)
      );
    } else {
      routineTabs = tabs.filter((tab) => ![TAB.CUSTOM_ROUTINES].includes(tab));
    }
  } else {
    routineTabs =
      userId === DEV_CUSTOM_ROUTINE_USER_ID || isUserAdmin
        ? tabs.slice()
        : tabs.filter((tab) => ![TAB.CUSTOM_ROUTINES].includes(tab));
  }
  return routineTabs;
};

export const getRoutineSuggestionsTabIndex = (
  tabTitle: string,
  routines: { title: string; value: boolean }[]
) => {
  const isBothRoutineSelected = routines.every((routine) => routine.value);
  if (isBothRoutineSelected) {
    return t('tab_morning') === t(tabTitle)
      ? RoutineSuggestionEditHabit.FIRST_TAB_INDEX
      : RoutineSuggestionEditHabit.SECOND_TAB_INDEX;
  }
  return RoutineSuggestionEditHabit.FIRST_TAB_INDEX;
};

export const getCopiedActivity = (activity: ActivityType) => ({
  ...activity,
  name: t('copy_of_activity_name', { activity_name: activity.name }),
  log_quantity_questions:
    activity?.log_quantity_questions?.map((question) => ({
      ...question,
      id: uuid()
    })) || []
});

export const isOnRoutineSuggestionRoute =
  window.location.pathname === ROUTES.WEBVIEW_ROUTINE_SUGGESTION;

export const getRoutineTriggerOptions = (custom_routine_name: string) => [
  {
    label: t('on_demand'),
    value: CUSTOM_ROUTINE_TRIGGER.ON_DEMAND,
    tooltip: t('tooltip_custom_routine_on_demand', {
      custom_routine_name
    })
  },
  {
    label: t('on_a_schedule'),
    value: CUSTOM_ROUTINE_TRIGGER.ON_SCHEDULE,
    tooltip: t('tooltip_custom_routine_schedule', {
      custom_routine_name
    })
  }
];

export const dayOfWeekLabels = {
  [DaysOfWeek.ALL]: 'days.all',
  [DaysOfWeek.SUN]: 'days.sunday',
  [DaysOfWeek.MON]: 'days.monday',
  [DaysOfWeek.TUE]: 'days.tuesday',
  [DaysOfWeek.WED]: 'days.wednesday',
  [DaysOfWeek.THU]: 'days.thursday',
  [DaysOfWeek.FRI]: 'days.friday',
  [DaysOfWeek.SAT]: 'days.saturday',
  [DaysOfWeek.WEEKDAYS]: 'days.weekdays',
  [DaysOfWeek.WEEKEND]: 'days.weekend'
};

export const updateSelectedDaysOfWeek = (days: string[]) =>
  days?.map((day) => ({
    label: t(dayOfWeekLabels[day as keyof typeof DaysOfWeek]),
    value: day
  }));

export const daysOfWeekOptions = Object.values(DaysOfWeek)
  .map((day) => ({
    label: t(dayOfWeekLabels[day as keyof typeof DaysOfWeek]),
    value: day
  }))
  .slice();

export const updateDaysOfWeek = (
  selectedDays: MultiValue<DropDownSelectedValue>
) => {
  if (selectedDays?.length) {
    let days: MultiValue<DropDownSelectedValue> = [];
    const selectedDay = selectedDays.at(-1)?.value as DaysOfWeek;
    if (selectedDay === DaysOfWeek.ALL) {
      days = updateSelectedDaysOfWeek([selectedDay]);
    } else if (WEEKEND.includes(selectedDay)) {
      days = selectedDays.filter(
        ({ value }) =>
          ![DaysOfWeek.WEEKEND, DaysOfWeek.ALL].includes(value as DaysOfWeek)
      );
    } else if (selectedDay === DaysOfWeek.WEEKEND) {
      days = selectedDays.filter(
        ({ value }) =>
          ![DaysOfWeek.ALL, DaysOfWeek.SAT, DaysOfWeek.SUN].includes(
            value as DaysOfWeek
          )
      );
    } else if (selectedDay === DaysOfWeek.WEEKDAYS) {
      days = selectedDays.filter(
        ({ value }) =>
          !WEEK_DAYS.concat([DaysOfWeek.ALL]).includes(value as DaysOfWeek)
      );
    } else {
      days = selectedDays.filter(
        (item) =>
          ![DaysOfWeek.ALL, DaysOfWeek.WEEKDAYS].includes(
            item.value as DaysOfWeek
          )
      );
    }
    return days.map((item) => item.value as DaysOfWeek);
  }

  return [];
};

export const isDarkThemeActivated = () =>
  store.getState().setting.themeMode === THEME_OPTION.DARK;

export const isMobilePlatform = () =>
  [PLATFORMS.ANDROID, PLATFORMS.IOS, PLATFORMS.MOB].includes(
    store.getState().setting.platform
  );

export const getTimeSummaries = (currentSettings: SettingsType) => {
  const summaries = [];
  const {
    morning_activities,
    evening_activities,
    startup_time,
    shutdown_time,
    cutoff_time_for_non_high_priority_activities
  } = currentSettings;

  const duration_of_morning_routine =
    getAllTypesOfRoutineDurationAsSecond(morning_activities);

  const duration_of_evening_routine =
    getHighPriorityRoutineDurationAsSecond(evening_activities);

  const morningRoutineStartTime = formatTimeTo12Hour(startup_time);
  const end_time = formatTimeTo12Hour(shutdown_time);
  const cutOffTime = formatTimeTo12Hour(
    cutoff_time_for_non_high_priority_activities
  );

  const morningRoutineEndTime = startup_time
    ? formatTimeTo12Hour(
        addMinutesToTime(
          startup_time,
          convertSecondsToMinutes(duration_of_morning_routine)
        )
      )
    : '';

  const eveningRoutineEndTime = formatTimeTo12Hour(
    cutoff_time_for_non_high_priority_activities ?? end_time,
    duration_of_evening_routine
  );

  if (startup_time) {
    summaries.push({
      title: t('summary.morning_routine_duration', {
        morningRoutineStartTime,
        morningRoutineEndTime
      }),
      tooltip: t('summary.tool_tip_morning_routine_duration')
    });
  }

  summaries.push(
    {
      title: t('summary.work_time', { morningRoutineEndTime, end_time }),
      tooltip: t('summary.tool_tip_work_time')
    },
    {
      title: t('summary.end_of_day_sleep_time', {
        eveningRoutineEndTime,
        morningRoutineStartTime
      }),
      tooltip: t('summary.tool_tip_end_of_day_sleep_time')
    }
  );

  if (cutOffTime) {
    summaries.splice(
      2,
      0,
      {
        title: t('summary.free_time', { end_time, cutOffTime }),
        tooltip: t('summary.tool_tip_free_time')
      },
      {
        title: t('summary.evening_routine_duration', {
          cutOffTime,
          eveningRoutineEndTime
        }),
        tooltip: t('summary.tool_tip_evening_routine_duration')
      }
    );
  }
  return summaries;
};
