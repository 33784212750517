import { createAppSelector } from 'store/hooks';

export const startGeneralCounterSelector = createAppSelector(
  [
    (state) => state.general.shouldStopMotivationalDelayCounter,
    (state) => state.general.motivationalDelayCounter
  ],
  (shouldStopMotivationalDelayCounter, motivationalDelayCounter) => ({
    shouldStopMotivationalDelayCounter,
    motivationalDelayCounter
  })
);
