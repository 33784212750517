import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MOTIVATIONAL_SUMMARY_DELAY_SECONDS } from 'constants/general';
import { initialGeneralState } from 'store/initial-states';

const general = createSlice({
  name: 'general',
  initialState: initialGeneralState,
  reducers: {
    incrementMotivationalDelayCounter: (state) => {
      state.motivationalDelayCounter = state.motivationalDelayCounter + 1;
    },
    updateShouldStopMotivationalDelayCounter: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.shouldStopMotivationalDelayCounter = payload;
    },
    resetMotivationalDelayCounter: (state) => {
      state.shouldStopMotivationalDelayCounter = false;
      state.motivationalDelayCounter = 0;
    },
    updateTabLayout: (state, { payload }) => {
      state.tabLayout.titles = payload;
    },
    updateTabLayoutActiveTab: (state, { payload }) => {
      state.tabLayout.activeTab = payload;
    },
    updateTabLayoutTitles: (state, { payload }: PayloadAction<string[]>) => {
      state.tabLayout.titles = payload;
    },
    setMotivationalDelayCounterToMaxDelayOnError: (state) => {
      state.motivationalDelayCounter = MOTIVATIONAL_SUMMARY_DELAY_SECONDS;
    }
  }
});

export const {
  incrementMotivationalDelayCounter,
  resetMotivationalDelayCounter,
  updateTabLayout,
  updateTabLayoutActiveTab,
  updateTabLayoutTitles,
  updateShouldStopMotivationalDelayCounter,
  setMotivationalDelayCounterToMaxDelayOnError
} = general.actions;
export default general.reducer;
