import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MAC_APP_DATA_LOCATION, PLATFORMS, ROUTINE } from 'constants/general';
import {
  changeCurrentSettings,
  updateAreSettingsSaving,
  updateIsSettingEditingFinished,
  updateIsUserSettingsModified,
  updateRoutineSuggestions,
  updateShowEditSuggestionsModal
} from 'store/reducer/setting/slice';
import { saveUserSettings } from 'store/reducer/setting/extra';
import { FLAG, Mode } from 'constants/enum';
import { ATTRIB } from 'constants/test';
import { upsertHabitPack } from 'store/reducer//habit-pack/extra';
import { upsertFocusModeTemplate } from 'store/reducer/focus-mode/extra';
import { filterOutCutoffTimeAndTutorial } from 'utils/support';
import { useEffect, useMemo } from 'react';
import {
  compareSettings,
  validateScheduleCustomRoutines
} from 'utils/validation';
import ValidationErrors from './ValidationErrors';
import { t } from 'i18next';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import { tabActionsSelector } from 'store/reducer/setting/selectors';
import { filterRoutinesInvalidActivities } from 'utils/settingsUtil';
import { MOBILE_APP_EVENT, PLATFORMS_MESSAGE_HANDLER } from 'constants/events';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';

const TabActions = () => {
  const dispatch = useAppDispatch();
  const {
    currentSettings,
    previousSettings,
    platform,
    mode,
    areSettingsValid: isDataValid,
    isUserSettingsModified,
    flags,
    customRoutine,
    currentViewedFocusTemplate,
    initialCurrentViewedFocusTemplate,
    initialCurrentViewedPack,
    routineTimeValidation
  } = useAppSelector(tabActionsSelector);

  useEffect(() => {
    const doSettingsModified = isUserSettingsEdited();
    dispatch(updateIsUserSettingsModified(doSettingsModified));
    flags.includes(FLAG.SETTINGS_UPDATED) &&
      sendDataToPlatform(
        platform,
        { areSettingsEdited: doSettingsModified, areSettingsValid },
        MAC_APP_DATA_LOCATION.USER_SETTINGS_UPDATED
      );
  }, [currentSettings, currentViewedFocusTemplate]);

  const [
    areRoutineTimesValid,
    isRoutineSuggestionsPage,
    areCustomRoutinesSchedulesInvalid
  ] = useMemo(
    () => [
      Object.values(routineTimeValidation.isValid).every(Boolean),
      mode === Mode.CUSTOM && customRoutine === ROUTINE.SUGGESTION,
      validateScheduleCustomRoutines(currentSettings.custom_routines)
    ],
    [mode, customRoutine, currentSettings, routineTimeValidation]
  );

  const areSettingsValid = [Mode.DEFAULT, Mode.FREESTYLE].includes(mode)
    ? Boolean(currentSettings.startup_time) &&
      Boolean(currentSettings.shutdown_time) &&
      Boolean(currentSettings.break_after_minutes) &&
      areRoutineTimesValid &&
      isDataValid
    : isDataValid;

  const [morning_habits, break_habits] = useMemo(
    () =>
      filterOutCutoffTimeAndTutorial(
        currentSettings.morning_activities ?? [],
        currentSettings.break_activities ?? []
      ),
    [currentSettings]
  );

  const handleFinish = async () => {
    if (isRoutineSuggestionsPage || platform === PLATFORMS.WEB) {
      handleDashboardSettings();
    } else {
      const { morning_activities, break_activities, evening_activities } =
        filterRoutinesInvalidActivities(
          morning_habits,
          break_habits,
          currentSettings.evening_activities ?? []
        );
      dispatch(updateIsSettingEditingFinished(true));
      if (flags.includes(FLAG.SAVE_SETTINGS_MIGRATE_TO_WEB)) {
        const response = await dispatch(
          saveUserSettings({
            ...currentSettings,
            has_edited_settings: compareSettings(
              {
                ...currentSettings,
                morning_activities,
                break_activities,
                evening_activities
              },
              previousSettings
            ),
            morning_activities,
            break_activities,
            evening_activities
          })
        );
        const isRequestSuccessful = response.meta.requestStatus === 'fulfilled';
        sendDataToPlatform(
          platform,
          isRequestSuccessful
            ? PLATFORMS_MESSAGE_HANDLER.USER_SETTINGS_UPDATED
            : PLATFORMS_MESSAGE_HANDLER.USER_SETTINGS_UPDATE_FAILED,
          MAC_APP_DATA_LOCATION.SAVED_USER_SETTINGS,
          MOBILE_APP_EVENT.SAVED_USER_SETTINGS
        );
      } else {
        handleSave(); // Add support for handling previous versions
      }
    }
  };

  const handleDashboardSettings = () => {
    const {
      morning_activities,
      break_activities,
      evening_activities,
      custom_routines
    } = filterRoutinesInvalidActivities(
      morning_habits,
      break_habits,
      currentSettings.evening_activities ?? [],
      currentSettings.custom_routines ?? []
    );
    if (isRoutineSuggestionsPage) {
      const activities = morning_activities.concat(evening_activities);
      dispatch(updateRoutineSuggestions(activities));
      dispatch(updateShowEditSuggestionsModal(false));
    } else {
      dispatch(updateIsSettingEditingFinished(true));
      if (window.location.href.includes('dashboard')) {
        if (
          areSettingsValid &&
          (mode === Mode.ROUTINE || mode === Mode.STANDALONE)
        ) {
          dispatch(upsertHabitPack(currentSettings));
        } else if (
          areSettingsValid &&
          mode === Mode.FOCUS_MODE &&
          currentViewedFocusTemplate
        ) {
          dispatch(upsertFocusModeTemplate(currentViewedFocusTemplate));
        } else {
          dispatch(updateAreSettingsSaving(true));
          dispatch(
            changeCurrentSettings({
              ...currentSettings,
              morning_activities,
              break_activities,
              evening_activities,
              custom_routines
            })
          );

          dispatch(
            saveUserSettings({
              ...currentSettings,
              has_edited_settings: compareSettings(
                {
                  ...currentSettings,
                  morning_activities,
                  break_activities,
                  evening_activities,
                  custom_routines
                },
                previousSettings
              ),
              morning_activities,
              break_activities,
              evening_activities,
              custom_routines
            })
          );
        }
      } else {
        mode === Mode.FREESTYLE &&
          dispatch(
            changeCurrentSettings({
              ...currentSettings,
              morning_activities: [],
              evening_activities: [],
              break_activities
            })
          );
      }
    }
  };

  const handleSave = () => {
    const {
      morning_activities,
      break_activities,
      evening_activities,
      custom_routines
    } = filterRoutinesInvalidActivities(
      morning_habits,
      break_habits,
      currentSettings.evening_activities ?? [],
      currentSettings.custom_routines ?? []
    );
    if ([Mode.DEFAULT, Mode.FREESTYLE, Mode.CUSTOM].includes(mode)) {
      sendDataToPlatform(
        platform,
        {
          ...currentSettings,
          morning_activities,
          break_activities,
          evening_activities,
          custom_routines
        },
        MAC_APP_DATA_LOCATION.SUBMIT_USER_SETTINGS
      );
    }
  };

  const isUserSettingsEdited = () => {
    if (
      platform === PLATFORMS.WEB &&
      (mode === Mode.ROUTINE || mode === Mode.STANDALONE)
    ) {
      return compareSettings(currentSettings, {
        ...currentSettings,
        ...initialCurrentViewedPack
      });
    } else if (
      platform === PLATFORMS.WEB &&
      mode === Mode.FOCUS_MODE &&
      initialCurrentViewedFocusTemplate
    ) {
      return compareSettings(
        currentViewedFocusTemplate ?? {},
        initialCurrentViewedFocusTemplate
      );
    } else {
      return compareSettings(currentSettings, previousSettings);
    }
  };

  const saveBtnTitle = useMemo(() => {
    if (customRoutine === ROUTINE.TIMING) {
      return t('save_block_schedule');
    } else if (isRoutineSuggestionsPage || platform === PLATFORMS.WEB) {
      return t('finish');
    } else {
      return t('save_restart');
    }
  }, [customRoutine, isRoutineSuggestionsPage, platform]);

  return isUserSettingsModified ? (
    <div className='w-full h-min self-center mb-2 flex items-center justify-center z-50 gap-5 pt-1'>
      <ValidationErrors />
      <ButtonFocusBear
        disabled={areCustomRoutinesSchedulesInvalid || !areRoutineTimesValid}
        onClick={handleFinish}
        id={ATTRIB.TEST.BUTTON_SAVE_SETTINGS}
        title={saveBtnTitle}
        disableDarkMode
      />
    </div>
  ) : null;
};

export default TabActions;
