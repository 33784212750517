import { DEFAULT_HTML_ATTRIBUTES } from 'assets/data';
import classNames from 'classnames';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE } from 'constants/enum';
import {
  BRAIN_DUMP_CHAR_LIMIT,
  LOCAL_STORAGE,
  MAC_APP_DATA_LOCATION,
  PLATFORMS
} from 'constants/general';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { convertBrianDumpToTasks } from 'store/reducer/to-do/extra';
import { brainDumpSelector } from 'store/reducer/to-do/selectors';
import {
  getLocalStorage,
  isAlphaNumericInput,
  updateLocalStorage
} from 'utils/support';
import { isDarkModeActivated } from 'utils/validation';
import BrainDumpConvertedTasksModal from './BrainDumpConvertedTasksModal';
import {
  sendBrainDumpToDesktopOrMobileApp,
  sendDataToPlatform
} from 'utils/focusBear_apps';
import { captureEvents } from 'utils/events';
import { POST_HOG_EVENT } from 'constants/events';
import { updatePlayerBrainDump } from 'store/reducer/to-do/slice';

const BrainDumpInput = () => {
  const dispatch = useAppDispatch();
  const { isConvertingBrianDump, brainDump, themeMode } =
    useAppSelector(brainDumpSelector);

  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  return (
    <div className='flex flex-col gap-1'>
      <textarea
        disabled={isConvertingBrianDump}
        value={brainDump}
        rows={3}
        onChange={({ target: { value } }) => {
          if (
            value.length <= BRAIN_DUMP_CHAR_LIMIT &&
            isAlphaNumericInput(value)
          ) {
            dispatch(updatePlayerBrainDump(value));
            updateLocalStorage(
              LOCAL_STORAGE.CURRENT_BRAIN_DUMP_CONTENTS,
              value
            );
          }
        }}
        placeholder={t('to_do_player.brain_dump_placeholder')}
        className={classNames(
          'w-full outline-none border border-gray-200 resize-none shadow !text-xs/sm p-2 rounded-md while_your_are_working_on_a_task scrollbar-thin',
          {
            'bg-gray-500': shouldActivateDarkMode,
            'bg-white': !shouldActivateDarkMode
          }
        )}
        {...DEFAULT_HTML_ATTRIBUTES}
      ></textarea>
      <span className='self-end text-xs'>{`${brainDump.length} / ${BRAIN_DUMP_CHAR_LIMIT}`}</span>
    </div>
  );
};

const BrainDump = () => {
  const dispatch = useAppDispatch();
  const {
    platform,
    isConvertingBrianDump,
    showBrainDumpTasksModal,
    userId,
    userEmail,
    brainDump
  } = useAppSelector(brainDumpSelector);

  useEffect(() => {
    sendBrainDumpToDesktopOrMobileApp();
  }, []);

  useEffect(() => {
    const brain_dump_content = decodeURIComponent(
      brainDump.replaceAll('"', '')
    );
    sendDataToPlatform(
      platform,
      platform === PLATFORMS.MAC ? brain_dump_content : { brain_dump_content },
      MAC_APP_DATA_LOCATION.SEND_BRAIN_DUMP_CONTENT
    );
  }, [brainDump]);

  const previousBrainDumpContents = useMemo(
    () => getLocalStorage(LOCAL_STORAGE.INITIAL_BRAIN_DUMP_CONTENTS),
    [showBrainDumpTasksModal]
  );

  return (
    <>
      <div className='w-full h-fit flex flex-col gap-2 relative mt-4'>
        <BrainDumpInput />
        {isConvertingBrianDump ? (
          <div className='w-4 h-4 border-t-2 border-sunRayFirst rounded-full animate-spin mx-2'></div>
        ) : (
          <ButtonFocusBear
            disabled={!brainDump || brainDump === previousBrainDumpContents}
            title={t('to_do_player.use_ai_to_convert_to_tasks')}
            size={BTN_FB_SIZE.SMALL}
            onClick={() => {
              if (!isConvertingBrianDump) {
                dispatch(convertBrianDumpToTasks(brainDump));
                captureEvents(
                  POST_HOG_EVENT.TODO_PLAYER_BRAINDUMP_AI_BUTTON_CLICKED,
                  userEmail,
                  {
                    userId
                  }
                );
              }
            }}
            additionalStyles='you_can_use_ai_to_convert_brain_dump'
          />
        )}
        <p className='absolute -top-4 left-2 px-2 py-0.5 rounded-t-md text-[0.55rem] bg-sunRayFirst text-white'>
          {t('to_do_player.brain_dump')}
        </p>
      </div>
      {showBrainDumpTasksModal ? <BrainDumpConvertedTasksModal /> : null}
    </>
  );
};

export default BrainDump;
