import { THEME_OPTION } from 'constants/enum';
import { createAppSelector } from 'store/hooks';

export const isTokenAddedToInterceptorSelector = createAppSelector(
  [(state) => state.setting.isTokenAddedToInterceptor],
  (isTokenAddedToInterceptor) => isTokenAddedToInterceptor
);

export const themeSelector = createAppSelector(
  [(state) => state.setting.themeMode?.slice() as THEME_OPTION],
  (theme) => theme
);

export const routineSuggestionsSelector = createAppSelector(
  [
    (state) => state.setting.themeMode.slice() as THEME_OPTION,
    (state) => state.setting.isTokenAddedToInterceptor,
    (state) => state.habitPack.isSendingInstalledHabitPackId,
    (state) => state.setting.areSuggestedHabitPacksInstalled,
    (state) => state.setting.showEditSuggestionsModal,
    (state) => state.setting.language
  ],
  (
    themeMode,
    isTokenAddedToInterceptor,
    isSendingInstalledHabitPackId,
    areSuggestedHabitPacksInstalled,
    showEditSuggestionsModal,
    language
  ) => ({
    themeMode,
    isTokenAddedToInterceptor,
    isSendingInstalledHabitPackId,
    areSuggestedHabitPacksInstalled,
    showEditSuggestionsModal,
    language
  })
);

export const routineSuggestionsWizardSelector = createAppSelector(
  [
    (state) => state.setting.routineSuggestionPayload,
    (state) => state.setting.platform,
    (state) => state.user.details?.email,
    (state) => state.user.details?.id,
    (state) => state.setting.areSuggestedHabitPacksInstalled,
    (state) => state.setting.routineSuggestions?.slice()
  ],
  (
    routineSuggestionPayload,
    platform,
    userEmail,
    userId,
    areSuggestedHabitPacksInstalled,
    routineSuggestions
  ) => ({
    routineSuggestionPayload,
    platform,
    userEmail,
    userId,
    areSuggestedHabitPacksInstalled,
    routineSuggestions
  })
);

export const routineGoalsSelector = createAppSelector(
  [
    (state) => state.user.details?.long_term_goals?.slice(),
    (state) => state.setting.routineSuggestionPayload.user_goals
  ],
  (long_term_goals, user_goals) => ({
    long_term_goals,
    user_goals
  })
);

export const listOfSelectedGoalsSelector = createAppSelector(
  [
    (state) => state.user.isLogTermGoalUpdating,
    (state) => state.setting.routineSuggestionPayload
  ],
  (isLogTermGoalUpdating, routineSuggestionPayload) => ({
    isLogTermGoalUpdating,
    routineSuggestionPayload
  })
);

export const selectedGoalSelector = createAppSelector(
  [(state) => state.user.details, (state) => state.user.isLogTermGoalUpdating],
  (details, isLogTermGoalUpdating) => ({ details, isLogTermGoalUpdating })
);

export const routineOptionsSelector = createAppSelector(
  [
    (state) => state.setting.isFetchingRoutineSuggestions,
    (state) => state.setting.routineSuggestionPayload,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email,
    (state) => state.setting.platform
  ],
  (
    isFetchingRoutineSuggestions,
    routineSuggestionPayload,
    userId,
    userEmail,
    platform
  ) => ({
    isFetchingRoutineSuggestions,
    routineSuggestionPayload,
    userId,
    userEmail,
    platform
  })
);

export const routineAvailableGoalsSelector = createAppSelector(
  [
    (state) => state.user.details,
    (state) => state.user.isLogTermGoalUpdating,
    (state) => state.setting.routineSuggestionPayload
  ],
  (details, isLogTermGoalUpdating, routineSuggestionPayload) => ({
    details,
    isLogTermGoalUpdating,
    routineSuggestionPayload
  })
);

export const routineExploreHabitPacksSelector = createAppSelector(
  [
    (state) => state.habitPack.onboardingHabitPacks.slice(),
    (state) => state.habitPack.areHabitPacksLoading
  ],
  (onboardingHabitPacks, areHabitPacksLoading) => ({
    onboardingHabitPacks,
    areHabitPacksLoading
  })
);

export const routineHabitPackDetailsSelector = createAppSelector(
  [
    (state) => state.habitPack.installingPack,
    (state) => state.setting.platform,
    (state) => state.habitPack.isSendingInstalledHabitPackId,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email
  ],
  (
    installingPack,
    platform,
    isSendingInstalledHabitPackId,
    userId,
    userEmail
  ) => ({
    installingPack,
    platform,
    isSendingInstalledHabitPackId,
    userId,
    userEmail
  })
);

export const suggestionsSelector = createAppSelector(
  [
    (state) => state.setting.routineSuggestions?.slice(),
    (state) => state.setting.routineSuggestionPayload.routines,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email,
    (state) => state.setting.platform
  ],
  (routineSuggestions, routines, userId, userEmail, platform) => ({
    routineSuggestions,
    routines,
    userId,
    userEmail,
    platform
  })
);

export const tabBodySelector = createAppSelector(
  [
    (state) => state.setting.mode,
    (state) => state.setting.tabs,
    (state) => state.setting.isShowMoreOptionsOpened,
    (state) => state.setting.moreOptions.type,
    (state) => state.setting.moreOptions.position,
    (state) => state.setting.isShowRoutineModal,
    (state) => state.setting.activeTabIndex,
    (state) => state.setting.isUnlockHabitPackModalShown,
    (state) => state.setting.platform,
    (state) => state.setting.themeMode.slice() as THEME_OPTION,
    (state) => state.setting.isSettingLoaded,
    (state) => state.setting.customRoutine,
    (state) => state.setting.flags.slice(),
    (state) => state.setting.routineSuggestionPayload.routines?.slice(),
    (state) => state.user.details?.id,
    (state) => state.user.isUserAdmin
  ],
  (
    mode,
    tabs,
    isShowMoreOptionsOpened,
    type,
    position,
    isShowRoutineModal,
    activeTabIndex,
    isUnlockHabitPackModalShown,
    platform,
    themeMode,
    isSettingLoaded,
    customRoutine,
    flags,
    routineSuggestionRoutines,
    userId,
    isUserAdmin
  ) => ({
    mode,
    tabs,
    isShowMoreOptionsOpened,
    type,
    position,
    isShowRoutineModal,
    activeTabIndex,
    isUnlockHabitPackModalShown,
    platform,
    themeMode,
    isSettingLoaded,
    customRoutine,
    flags,
    routineSuggestionRoutines,
    userId,
    isUserAdmin
  })
);

export const morningActivitiesSelector = createAppSelector(
  [
    (state) => state.setting.currentSettings.morning_activities?.slice(),
    (state) => state.filter
  ],
  (morning_activities, filter) => ({ morning_activities, filter })
);

export const eveningActivitiesSelector = createAppSelector(
  [
    (state) => state.setting.currentSettings.evening_activities?.slice(),
    (state) => state.filter,
    (state) => state.setting.mode
  ],
  (evening_activities, filter, mode) => ({ evening_activities, filter, mode })
);

export const routineActivitiesSelector = createAppSelector(
  [
    (state) => state.general.tabLayout.activeTab,
    (state) => state.setting.routineSuggestionPayload.routines,
    (state) => state.setting.routineSuggestions,
    (state) => state.setting.isInstallingRoutineSuggestions
  ],
  (activeTab, routines, routineSuggestions) => ({
    activeTab,
    routines,
    routineSuggestions
  })
);

export const routineSelectionSelector = createAppSelector(
  [(state) => state.setting.routineSuggestionPayload],
  (routineSuggestionPayload) => routineSuggestionPayload
);

export const suggestionsActionsSelector = createAppSelector(
  [
    (state) => state.setting.platform,
    (state) => state.setting.currentSettings,
    (state) => state.setting.isInstallingRoutineSuggestions,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email,
    (state) => state.setting.routineSuggestions?.slice()
  ],
  (
    platform,
    currentSettings,
    isInstallingRoutineSuggestions,
    userId,
    userEmail,
    routineSuggestions
  ) => ({
    platform,
    currentSettings,
    isInstallingRoutineSuggestions,
    userId,
    userEmail,
    routineSuggestions
  })
);

export const errorPageSelector = createAppSelector(
  [(state) => ({ ...state.setting.error })],
  (error) => error
);

export const errorMessageSelector = createAppSelector(
  [
    (state) => ({ ...state.setting.error }),
    (state) => state.user.subscriptionInfo
  ],
  (error, subscriptionInfo) => ({ error, subscriptionInfo })
);

export const habitPackAndLibraryActivitiesAutocompleteInputSelector =
  createAppSelector(
    [
      (state) => state.setting.activityLibrary?.slice(),
      (state) => state.modal.choice.choices?.slice(),
      (state) => state.habitPack.marketplaceApprovedPacks?.slice(),
      (state) => state.setting.themeMode
    ],
    (activityLibrary, choices, marketplaceApprovedPacks, themeMode) => ({
      activityLibrary,
      choices,
      marketplaceApprovedPacks,
      themeMode
    })
  );

export const timingSelector = createAppSelector(
  [
    (state) => state.setting.currentSettings.startup_time,
    (state) => state.setting.currentSettings.shutdown_time,
    (state) => state.setting.currentSettings.break_after_minutes,
    (state) =>
      state.setting.currentSettings
        .cutoff_time_for_non_high_priority_activities,
    (state) => state.setting.routineTimeValidation
  ],
  (
    startup_time,
    shutdown_time,
    break_after_minutes,
    cutoff_time_for_non_high_priority_activities,
    routineTimeValidation
  ) => ({
    startup_time,
    shutdown_time,
    break_after_minutes,
    cutoff_time_for_non_high_priority_activities,
    routineTimeValidation
  })
);

export const platformSelector = createAppSelector(
  [(state) => state.setting.platform],
  (platform) => platform
);

export const enableEditHabitsSelector = createAppSelector(
  [
    (state) => state.setting.platform,
    (state) => state.setting.flags?.slice(),
    (state) => state.setting.themeMode
  ],
  (platform, flags, themeMode) => ({ platform, flags, themeMode })
);

export const statsPageSelector = createAppSelector(
  [
    (state) => state.setting.platform,
    (state) => state.setting.font,
    (state) => state.setting.themeMode
  ],
  (platform, font, themeMode) => ({ platform, font, themeMode })
);

export const cutoffTimeForNonHighPriorityActivitiesSelector = createAppSelector(
  [
    (state) => state.setting.currentSettings.break_after_minutes,
    (state) =>
      state.setting.currentSettings
        .cutoff_time_for_non_high_priority_activities,
    (state) => state.setting.routineTimeValidation
  ],
  (
    break_after_minutes,
    cutoff_time_for_non_high_priority_activities,
    routineTimeValidation
  ) => ({
    break_after_minutes,
    cutoff_time_for_non_high_priority_activities,
    routineTimeValidation
  })
);

export const timingSummarySelector = createAppSelector(
  [
    (state) => state.setting.currentSettings.startup_time,
    (state) => state.setting.currentSettings.shutdown_time,

    (state) =>
      state.setting.currentSettings
        .cutoff_time_for_non_high_priority_activities,
    (state) => state.setting.currentSettings.morning_activities?.slice(),
    (state) => state.setting.currentSettings.evening_activities?.slice()
  ],
  (
    startup_time,
    shutdown_time,
    cutoff_time_for_non_high_priority_activities,
    morning_activities,
    evening_activities
  ) => ({
    startup_time,
    shutdown_time,
    cutoff_time_for_non_high_priority_activities,
    morning_activities,
    evening_activities
  })
);

export const addHabitButtonSelector = createAppSelector(
  [
    (state) => state.setting.currentSettings,
    (state) => state.setting.tabs?.slice(),
    (state) => state.setting.activeTabIndex,
    (state) => state.setting.customRoutine,
    (state) => state.setting.routineSuggestionPayload.routines?.slice(),
    (state) => state.setting.platform
  ],
  (
    currentSettings,
    tabs,
    activeTabIndex,
    customRoutine,
    routines,
    platform
  ) => ({
    currentSettings,
    tabs,
    activeTabIndex,
    customRoutine,
    routines,
    platform
  })
);

export const tabBodyHeaderSelector = createAppSelector(
  [
    (state) => state.setting.tabs?.slice(),
    (state) => state.setting.activeTabIndex,
    (state) => state.setting.mode
  ],
  (tabs, activeTabIndex, mode) => ({
    tabs,
    activeTabIndex,
    mode
  })
);

export const tabBodyHeaderWidgetsSelector = createAppSelector(
  [
    (state) => state.filter.isSettingToolbarShown,
    (state) => state.setting.activeTabIndex,
    (state) => state.setting.tabs?.slice(),
    (state) => state.setting.customRoutine
  ],
  (isSettingToolbarShown, activeTabIndex, tabs, customRoutine) => ({
    isSettingToolbarShown,
    activeTabIndex,
    tabs,
    customRoutine
  })
);

export const timingWrapperSelector = createAppSelector(
  [
    (state) => state.setting.platform,
    (state) => state.setting.customRoutine,
    (state) => state.setting.currentSettings.evening_activities,
    (state) => state.user.details?.focus_modes ?? [],
    (state) =>
      state.setting.currentSettings
        .cutoff_time_for_non_high_priority_activities ?? '',
    (state) => state.setting.currentSettings.shutdown_time,
    (state) => state.setting.currentSettings.startup_time
  ],
  (
    platform,
    customRoutine,
    evening_activities,
    user_focus_modes,
    cutoff_time,
    shutdown_time,
    startup_time
  ) => ({
    platform,
    customRoutine,
    evening_activities,
    user_focus_modes,
    cutoff_time,
    shutdown_time,
    startup_time
  })
);

export const newActivityButtonSelector = createAppSelector(
  [
    (state) => state.setting.themeMode,
    (state) => state.setting.activeTabIndex,
    (state) => state.setting.tabs,
    (state) => state.setting.currentSettings,
    (state) => state.setting.customRoutine,
    (state) => state.user.details?.focus_modes ?? []
  ],
  (
    themeMode,
    activeTabIndex,
    tabs,
    currentSettings,
    customRoutine,
    user_focus_modes
  ) => ({
    themeMode,
    activeTabIndex,
    tabs,
    currentSettings,
    customRoutine,
    user_focus_modes
  })
);

export const tabBodyRowDurationSelector = createAppSelector(
  [
    (state) => state.modal.moreOption.activity,
    (state) => state.setting.themeMode,
    (state) => state.setting.currentSettings.evening_activities,
    (state) =>
      state.setting.currentSettings
        .cutoff_time_for_non_high_priority_activities,
    (state) => state.setting.currentSettings.shutdown_time,
    (state) => state.user.details?.focus_modes ?? []
  ],
  (
    activity,
    themeMode,
    evening_activities,
    cutoff_time,
    shutdown_time,
    user_focus_modes
  ) => ({
    activity,
    themeMode,
    evening_activities,
    cutoff_time,
    shutdown_time,
    user_focus_modes
  })
);

export const modalContentHeaderHabitDurationSelector = createAppSelector(
  [
    (state) => state.modal.moreOption.activity,
    (state) => state.setting.themeMode,
    (state) => state.setting.currentSettings.evening_activities,
    (state) =>
      state.setting.currentSettings
        .cutoff_time_for_non_high_priority_activities,
    (state) => state.setting.currentSettings.shutdown_time,
    (state) => state.user.details?.focus_modes ?? []
  ],
  (
    activity,
    themeMode,
    evening_activities,
    cutoff_time,
    shutdown_time,
    user_focus_modes
  ) => ({
    activity,
    themeMode,
    evening_activities,
    cutoff_time,
    shutdown_time,
    user_focus_modes
  })
);

export const filterWidgetActionsSelector = createAppSelector(
  [
    (state) => state.filter.isSettingToolbarShown,
    (state) => state.setting.activeTabIndex,
    (state) => state.setting.tabs?.slice(),
    (state) => state.setting.customRoutine
  ],
  (isSettingToolbarShown, activeTabIndex, tabs, customRoutine) => ({
    isSettingToolbarShown,
    activeTabIndex,
    tabs,
    customRoutine
  })
);

export const addNewActivityButtonSelector = createAppSelector(
  [
    (state) => state.setting.activeTabIndex,
    (state) => state.setting.tabs?.slice(),
    (state) => state.setting.customRoutine,
    (state) => state.setting.themeMode,
    (state) => state.setting.currentSettings,
    (state) => state.setting.routineSuggestionPayload.routines
  ],
  (
    activeTabIndex,
    tabs,
    customRoutine,
    themeMode,
    currentSettings,
    routineSuggestionsSelectedRoutines
  ) => ({
    activeTabIndex,
    tabs,
    customRoutine,
    themeMode,
    currentSettings,
    routineSuggestionsSelectedRoutines
  })
);

export const tabActionsSelector = createAppSelector(
  [
    (state) => state.setting.currentSettings,
    (state) => state.setting.previousSettings,
    (state) => state.setting.platform,
    (state) => state.setting.mode,
    (state) => state.setting.areSettingsValid,
    (state) => state.setting.isUserSettingsModified,
    (state) => state.setting.flags,
    (state) => state.setting.customRoutine,
    (state) => state.focusMode.currentViewedFocusTemplate,
    (state) => state.focusMode.initialCurrentViewedFocusTemplate,
    (state) => state.habitPack.initialCurrentViewedPack,
    (state) => state.setting.routineTimeValidation
  ],
  (
    currentSettings,
    previousSettings,
    platform,
    mode,
    areSettingsValid,
    isUserSettingsModified,
    flags,
    customRoutine,
    currentViewedFocusTemplate,
    initialCurrentViewedFocusTemplate,
    initialCurrentViewedPack,
    routineTimeValidation
  ) => ({
    currentSettings,
    previousSettings,
    platform,
    mode,
    areSettingsValid,
    isUserSettingsModified,
    flags,
    customRoutine,
    currentViewedFocusTemplate,
    initialCurrentViewedFocusTemplate,
    initialCurrentViewedPack,
    routineTimeValidation
  })
);

export const validationErrorsSelector = createAppSelector(
  [
    (state) => state.setting.currentSettings,
    (state) => state.setting.validationErrors,
    (state) => state.setting.mode,
    (state) => state.setting.themeMode,
    (state) => state.focusMode.currentViewedFocusTemplate
  ],
  (
    currentSettings,
    validationErrors,
    mode,
    themeMode,
    currentViewedFocusTemplate
  ) => ({
    currentSettings,
    validationErrors,
    mode,
    themeMode,
    currentViewedFocusTemplate
  })
);

export const copyActivitySelector = createAppSelector(
  [
    (state) => state.modal.moreOption.activity,
    (state) => state.setting.moreOptions
  ],
  (activity, moreOptions) => ({
    activity,
    moreOptions
  })
);

export const routineSuggestionSelector = createAppSelector(
  [
    (state) => state.setting.platform,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email
  ],
  (platform, userId, userEmail) => ({
    platform,
    userId,
    userEmail
  })
);

export const tabBodyRowNameSelector = createAppSelector(
  [(state) => state.setting.mode],
  (mode) => mode
);

export const moreOptionModalActionsSelector = createAppSelector(
  [
    (state) => state.modal.moreOption,
    (state) => state.setting.moreOptions,
    (state) => state.setting.currentSettings,
    (state) => state.setting.mode,
    (state) => state.setting.activityLibrary,
    (state) => state.course.tutorials
  ],
  (
    moreOption,
    moreOptions,
    currentSettings,
    mode,
    activityLibrary,
    tutorials
  ) => ({
    moreOption,
    moreOptions,
    currentSettings,
    mode,
    activityLibrary,
    tutorials
  })
);

export const settingsPageSelector = createAppSelector(
  [
    (state) => state.setting.loadingSchema,
    (state) => state.setting.isEmbeddedWebViewLoading,
    (state) => state.setting.platform,
    (state) => state.setting.isSettingLoading,
    (state) => state.setting.isTokenAddedToInterceptor,
    (state) => state.setting.language,
    (state) => state.setting.isPlatformCoursesSyncing,
    (state) => state.setting.mode,
    (state) => state.setting.flags,
    (state) => state.setting.themeMode,
    (state) => state.habitPack.areHabitPacksLoading
  ],
  (
    loadingSchema,
    isEmbeddedWebViewLoading,
    platform,
    isSettingLoading,
    isTokenAddedToInterceptor,
    language,
    isPlatformCoursesSyncing,
    mode,
    flags,
    themeMode,
    areHabitPacksLoading
  ) => ({
    loadingSchema,
    isEmbeddedWebViewLoading,
    platform,
    isSettingLoading,
    isTokenAddedToInterceptor,
    language,
    isPlatformCoursesSyncing,
    mode,
    flags,
    themeMode,
    areHabitPacksLoading
  })
);

export const moreOptionsApplicationsSelector = createAppSelector(
  [
    (state) => state.setting.suggestions,
    (state) => state.setting.platform,
    (state) => state.modal.moreOption.activity,
    (state) => state.modal.moreOption.isUseApplicationsChecked,
    (state) => state.modal.moreOption.isUseWebsiteChecked
  ],
  (
    suggestions,
    platform,
    activity,
    isUseApplicationsChecked,
    isUseWebsiteChecked
  ) => ({
    suggestions,
    platform,
    activity,
    isUseApplicationsChecked,
    isUseWebsiteChecked
  })
);

export const moreOptionsWebsitesSelector = createAppSelector(
  [
    (state) => state.modal.moreOption.activity,
    (state) => state.modal.moreOption.isUseWebsiteChecked
  ],
  (activity, isUseWebsiteChecked) => ({
    activity,
    isUseWebsiteChecked
  })
);
