import { PropsWithChildren, useCallback, useMemo } from 'react';
import ModalOverlay from 'components/shared/ModalOverlay';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BrainDumpTask } from 'interfaces/commonInterface';
import CloseFilled from 'assets/icons/CloseFilled';
import {
  updateBrainDumpModalConvertedTasks,
  updateIsAddingConvertedTasksToPlayer,
  updatePlayerTasks,
  updateShowBrainDumpTasksModal
} from 'store/reducer/to-do/slice';
import COLOR from 'constants/color';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE, TO_DO_STATUS } from 'constants/enum';
import { createOrUpdateToDo, getRecentToDos } from 'store/reducer/to-do/extra';
import { DEFAULT_TODO_NEW_TASK } from 'assets/data';
import { EISENHOWER_QUADRANT, LOCAL_STORAGE } from 'constants/general';
import {
  convertToSeconds,
  formatToDoPlayerTaskTimeConstraints,
  increment,
  updateLocalStorage
} from 'utils/support';
import { t } from 'i18next';
import InfoCircle from 'assets/icons/InfoCircle';
import BrainDumpItem from './BrainDumpItem';
import { brainDumpConvertedTasksModalSelector } from 'store/reducer/to-do/selectors';
import moment from 'moment';
import classNames from 'classnames';
import { ToDoPlayerTask } from 'interfaces';
import { isDarkModeActivated } from 'utils/validation';
import { themeSelector } from 'store/reducer/setting/selectors';

const Wrapper = ({ children }: PropsWithChildren<object>) => {
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  return (
    <div
      className={`relative left-1.5 top-[99%] -translate-y-full w-[95%] sm:w-3/4 lg:w-1/2 h-fit flex flex-col items-center gap-4 shadow-lg rounded-md ${shouldActivateDarkMode ? 'bg-gray-500' : 'bg-focusBear'} pl-2 pr-4 pb-2 pt-6 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 animate-bottomUpFast`}
    >
      {children}
    </div>
  );
};

const BrainDumpConvertedTasksModal = () => {
  const dispatch = useAppDispatch();
  const {
    brainDumpModalConvertedTasks,
    isAddingConvertedTasksToPlayer,
    playerTasks,
    brainDumpContents
  } = useAppSelector(brainDumpConvertedTasksModalSelector);

  const areTasksInValid = brainDumpModalConvertedTasks.some(
    (task) => !task.task_name || !task.estimated_duration_minutes
  );

  const { player_tasks_count, procrastinate_tasks_count } = useMemo(
    () =>
      brainDumpModalConvertedTasks.reduce(
        (counter, task) => {
          if (task.selected) {
            counter.player_tasks_count = increment(counter.player_tasks_count);
          } else {
            counter.procrastinate_tasks_count = increment(
              counter.procrastinate_tasks_count
            );
          }
          return counter;
        },
        { player_tasks_count: 0, procrastinate_tasks_count: 0 }
      ),
    [brainDumpModalConvertedTasks]
  );

  const handleBrainDumpActions = useCallback(
    (position: number, value?: BrainDumpTask) => {
      const tasks = value
        ? brainDumpModalConvertedTasks.map((task, index) =>
            position === index ? value : task
          )
        : brainDumpModalConvertedTasks.filter((_, index) => position !== index);
      !value && dispatch(updateBrainDumpModalConvertedTasks(tasks));
    },
    [brainDumpModalConvertedTasks]
  );

  const handleProceed = async () => {
    dispatch(updateIsAddingConvertedTasksToPlayer(true));
    const responses = await Promise.allSettled([
      brainDumpModalConvertedTasks.map((task) =>
        dispatch(
          createOrUpdateToDo({
            payload: {
              ...DEFAULT_TODO_NEW_TASK,
              title: task.task_name,
              duration: convertToSeconds(task.estimated_duration_minutes),
              eisenhower_quadrant: parseInt(EISENHOWER_QUADRANT.DO),
              tags: [],
              subtasks: [],
              status: TO_DO_STATUS.NOT_STARTED,
              id: task.id
            },
            fromToDoPlayer: true
          })
        )
      )
    ]);

    for (const response of responses) {
      const createdTasks: ToDoPlayerTask[] = [];
      if (response.status === 'fulfilled') {
        const promises = response.value.map(async (promise, index) => {
          const data = await promise.unwrap();
          if (data && brainDumpModalConvertedTasks[index].selected) {
            createdTasks.splice(index, 0, data);
          }
          return data;
        });
        await Promise.allSettled(promises);

        handleAllTasksCompleted(createdTasks);
      }
    }
  };

  const handleAllTasksCompleted = useCallback(
    (createdTasks: ToDoPlayerTask[]) => {
      dispatch(
        updatePlayerTasks(
          formatToDoPlayerTaskTimeConstraints([
            ...playerTasks,
            ...createdTasks.filter(Boolean)
          ])
        )
      );
      dispatch(
        getRecentToDos(moment().subtract(7, 'days').format('YYYY-MM-DD'))
      );
      dispatch(updateIsAddingConvertedTasksToPlayer(false));
      dispatch(updateBrainDumpModalConvertedTasks([]));
      updateLocalStorage(
        LOCAL_STORAGE.INITIAL_BRAIN_DUMP_CONTENTS,
        brainDumpContents
      );
      dispatch(updateShowBrainDumpTasksModal(false));
    },
    []
  );

  return (
    <ModalOverlay>
      <Wrapper>
        <button
          onClick={() => {
            dispatch(updateShowBrainDumpTasksModal(false));
            dispatch(updateBrainDumpModalConvertedTasks([]));
          }}
          className='absolute top-0 right-0'
        >
          <CloseFilled fill={COLOR.RED} />
        </button>
        <div className='w-full h-fit max-h-[80vh] p-3 flex flex-col gap-4 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400'>
          {brainDumpModalConvertedTasks.map((task, position) => (
            <BrainDumpItem
              key={task.task_name}
              task={task}
              position={position}
              handleBrainDumpActions={handleBrainDumpActions}
            />
          ))}
        </div>
        <div className='flex items-center gap-1'>
          <InfoCircle styles='w-4 sm:w-5 h-auto' />
          <p className='text-xs sm:text-sm'>
            {t('to_do_player.tasks_will_be_added_and_saved_for_later', {
              player_tasks_count,
              procrastinate_tasks_count
            })}
          </p>
        </div>
        <ButtonFocusBear
          title={t('to_do_player.proceed')}
          onClick={handleProceed}
          size={BTN_FB_SIZE.SMALL}
          disabled={areTasksInValid || isAddingConvertedTasksToPlayer}
          additionalStyles={classNames({
            'animate-pulse': isAddingConvertedTasksToPlayer,
            'animate-none': !isAddingConvertedTasksToPlayer
          })}
        />
      </Wrapper>
    </ModalOverlay>
  );
};

export default BrainDumpConvertedTasksModal;
