import { t } from 'i18next';
import React from 'react';

export const INTRO_TO_DO_PLAYER_STEPS = (image: React.ReactNode = null) => ({
  FIRST: [
    {
      target: '.here_is_the_intention',
      content: t('intro.here_is_the_intention'),
      disableBeacon: true
    },
    {
      target: '.add_specific_tasks',
      content: t('intro.add_specific_tasks'),
      disableBeacon: true
    }
  ],
  SECOND: [
    {
      target: '.enter_the_name_of_your_task_here',
      content: t('intro.enter_the_name_of_your_task_here'),
      disableBeacon: true
    }
  ],
  THIRD: [
    {
      target: '.time_to_do_the_task',
      content: t('intro.time_to_do_the_task'),
      disableBeacon: true
    },
    {
      target: '.start_working_on_the_task',
      content: t('intro.start_working_on_the_task'),
      disableBeacon: true
    }
  ],
  FOURTH: [
    {
      target: '.while_your_are_working_on_a_task',
      content: t('intro.while_your_are_working_on_a_task'),
      disableBeacon: true
    },
    {
      target: '.you_can_use_ai_to_convert_brain_dump',
      content: t('intro.you_can_use_ai_to_convert_brain_dump'),
      disableBeacon: true
    },
    {
      target: '.this_time_shows_you_how_much_time_left',
      content: t('intro.this_time_shows_you_how_much_time_left'),
      disableBeacon: true
    },
    {
      target: '.if_you_finish_the_task_early',
      content: t('intro.if_you_finish_the_task_early'),
      disableBeacon: true
    },
    {
      target: '.hidden_minimize_button',
      content: t('intro.click_to_minimize_screen'),
      disableBeacon: true
    },
    {
      target: '.hidden_minimize_button',
      content: image,
      disableBeacon: true
    }
  ]
});
