import { useState, useContext, memo } from 'react';
import {
  updateSettingFeatures,
  updateMoreOptions,
  updateIsShowMoreOptionsOpened,
  updateIsUnlockHabitPackModalShown,
  removeHabit
} from 'store/reducer/setting/slice';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  ACTIVITY_TYPES,
  EMPTY_STRING,
  FIRST_ACTIVITY_INDEX,
  HABIT_SETTING_FEATURES,
  QUOTE
} from 'constants/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateAddToCalendarActivity,
  updateAddToCalendarShowAddToCalendarModalOpened,
  updateMoreOptionActivity
} from 'store/reducer/modal/slice';
import CloneLibraryActivityButton from 'components/common/buttons/CloneLibraryActivityButton';
import Switch from 'react-switch';
import { SWITCH } from 'constants/tag';
import { isDarkModeActivated } from 'utils/validation';
import CloseCircle from 'assets/icons/CloseCircle';
import COLOR from 'constants/color';
import { ATTRIB } from 'constants/test';
import CalendarPlus from 'assets/icons/CalendarPlus';
import Tooltip from 'components/common/Tooltip';
import TabBodyRowIcons from './tabBodyRowIcons';
import { TabBodyRowContext } from '.';
import { t } from 'i18next';
import { ROUTES } from 'constants/routes';
import postHog from 'posthog-js';

interface CustomUndoToastProps {
  activityName: string;
  handleConfirmDelete: (arg?: boolean) => void;
}

const CustomUndoToast = ({
  activityName,
  handleConfirmDelete
}: CustomUndoToastProps) => {
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector((state) => state.setting).themeMode
  );

  return (
    <div
      className={`w-96 h-fit flex flex-col items-center justify-center ${
        shouldActivateDarkMode
          ? 'bg-gray-700 border border-white'
          : 'bg-focusBear'
      } gap-4 rounded-lg px-4 py-6 relative`}
    >
      <p
        className={`w-full text-center line-clamp-2 capitalize ${
          shouldActivateDarkMode ? 'text-white' : 'text-black'
        } font-semibold`}
      >
        {activityName
          ? t('activity_deleted', { activity_name: activityName })
          : t('activity_deleted', { activity_name: EMPTY_STRING }).replaceAll(
              QUOTE,
              EMPTY_STRING
            )}
      </p>
      <div className='w-full flex items-center justify-center gap-4 text-white'>
        <button
          onClick={() => handleConfirmDelete(true)}
          className='w-fit h-fit px-2 py-1 rounded-md bg-blue-500 hover:bg-blue-600 text-xs md:text-sm 2xl:text-base'
        >
          {t('undo')}
        </button>
        <button
          onClick={() => handleConfirmDelete()}
          className='w-fit h-fit px-2 py-1 md rounded-md bg-red-500 hover:bg-red-600 text-xs md:text-sm 2xl:text-base'
        >
          {t('confirm_delete')}
        </button>
      </div>
    </div>
  );
};

const DeleteBtn = () => {
  const dispatch = useAppDispatch();
  const [toastId, setToastId] = useState(EMPTY_STRING);
  const { type, position, currentActivity, customRoutinePosition } =
    useContext(TabBodyRowContext);
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector((state) => state.setting).themeMode
  );

  const handleConfirmDelete = (isUndo?: boolean) => {
    if (!isUndo) {
      dispatch(removeHabit({ type, position, customRoutinePosition }));
      postHog.capture('delete-habit-confirm', {
        'routine-type': type
      });
    }
    toast.dismiss(toastId);
    setToastId(EMPTY_STRING);
  };

  const handleDelete = () => {
    if (!toast.isActive(toastId)) {
      toast(
        ({ toastProps: { toastId } }) => {
          setToastId(toastId.toString());
          return (
            <CustomUndoToast
              activityName={currentActivity?.name ?? EMPTY_STRING}
              handleConfirmDelete={handleConfirmDelete}
            />
          );
        },
        {
          position: 'top-center',
          className: `w-fit ${
            shouldActivateDarkMode ? 'bg-gray-700' : 'bg-focusBear'
          } rounded-lg`,
          closeOnClick: false,
          closeButton: false,
          autoClose: false
        }
      );
    }
  };

  return (
    <button
      data-test={ATTRIB.TEST.BUTTON_DELETE_ACTIVITY}
      data-locator={ATTRIB.LOCATOR.DELETE_ACTIVITY}
      className='w-fit h-fit cursor-pointer  absolute -top-8 bottom-[calc(100%-4px)] left-[calc(100%-4px)] bg-white rounded-full md:relative md:bottom-0 md:left-0 md:bg-transparent'
      onClick={handleDelete}
    >
      <CloseCircle
        fill={COLOR.RED}
        fillDarkMode={COLOR.RED}
        styles='~w-4/5 ~h-4/5 bg-white rounded-full'
      />
    </button>
  );
};

const TabBodyRowActions = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { activityLibrary } = useAppSelector((state) => state.setting);
  const {
    type,
    position,
    currentActivity,
    setShouldUseTimer,
    shouldUseTimer,
    isEditingAllowed,
    customRoutinePosition
  } = useContext(TabBodyRowContext);
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector((state) => state.setting).themeMode
  );

  const handleSwitchUseTimer = (selected: boolean) => {
    if (!isEditingAllowed) {
      dispatch(updateIsUnlockHabitPackModalShown(true));
    } else {
      dispatch(
        updateSettingFeatures({
          type,
          position,
          feature: HABIT_SETTING_FEATURES.COMPLETION_REQUIREMENTS,
          value: selected ? null : EMPTY_STRING,
          customRoutinePosition
        })
      );
      setShouldUseTimer?.(selected);
    }
  };

  return (
    <div className='w-full md:w-3/5 lg:w-[65%] xl:w-3/4 2xl:w-3/5 h-full flex items-center justify-end pt-3.5 gap-4 lg:gap-4 xl:gap-6'>
      {type === ACTIVITY_TYPES.LIBRARY && (
        <CloneLibraryActivityButton
          activityPosition={position}
          type={ACTIVITY_TYPES.LIBRARY}
        />
      )}
      <div className='w-fit min-w-[5%] flex items-center tracking-wide gap-2 md:gap-1 text-xs md:text-sm pb-0.5 font-medium'>
        {window.location.pathname !== ROUTES.WEBVIEW_ROUTINE_SUGGESTION && (
          <>
            <Switch
              width={SWITCH.WIDTH.USE_TIMER}
              height={SWITCH.HEIGHT.USE_TIMER}
              onChange={handleSwitchUseTimer}
              checked={shouldUseTimer}
              data-test={ATTRIB.TEST.SWITCH_USE_TIMER}
              id={ATTRIB.TEST.SWITCH_USE_TIMER}
              data-locator={ATTRIB.LOCATOR.USE_TIMER}
            />
            {t('use_timer')}
            <Tooltip
              message={t('user_timer_description')}
              place={position === FIRST_ACTIVITY_INDEX ? 'bottom' : 'top'}
            />
          </>
        )}
      </div>
      {type === ACTIVITY_TYPES.LIBRARY ? (
        <button
          className='hover:bg-gray-200 rounded-full'
          onClick={() => {
            dispatch(updateAddToCalendarActivity(activityLibrary[position]));
            dispatch(updateAddToCalendarShowAddToCalendarModalOpened(true));
          }}
        >
          <CalendarPlus styles='w-5 h-5 md:w-6 md:h-6' />
        </button>
      ) : null}
      {window.location.pathname !== ROUTES.WEBVIEW_ROUTINE_SUGGESTION && (
        <button
          data-test={ATTRIB.TEST.BUTTON_MORE_OPTION}
          data-locator={ATTRIB.LOCATOR.MORE_OPTION}
          onClick={() => {
            if (!isEditingAllowed) {
              dispatch(updateIsUnlockHabitPackModalShown(true));
            } else {
              currentActivity &&
                dispatch(updateMoreOptionActivity(currentActivity));
              dispatch(updateMoreOptions({ type, position }));
              dispatch(updateIsShowMoreOptionsOpened(true));
            }
          }}
          className={`min-w-max w-fit h-fit text-[9px] lg:text-[11px] tracking-wide rounded-md px-2 py-1 transition ease-in-out delay-100 hover:scale-110 ${
            shouldActivateDarkMode ? 'bg-gray-800' : 'bg-gray-700'
          } hover:bg-gray-800 duration-300 text-white`}
        >
          {t('more_options')}
        </button>
      )}
      <TabBodyRowIcons />
      <DeleteBtn />
    </div>
  );
};

export default memo(TabBodyRowActions);
