import InputTime from 'components/common/inputs/InputTime';
import { t } from 'i18next';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { timingSummarySelector } from 'store/reducer/setting/selectors';
import { updateShutdownTime } from 'store/reducer/setting/slice';

const ShutdownTime = ({ wrapperStyles }: { wrapperStyles?: string }) => {
  const dispatch = useAppDispatch();
  const { shutdown_time } = useAppSelector(timingSummarySelector);

  return (
    <InputTime
      selectedDate={shutdown_time ?? ''}
      setSelectedDate={(value: string) => {
        dispatch(updateShutdownTime(value));
      }}
      errorMessages={[t('errors.empty_time')]}
      wrapperStyles={wrapperStyles}
    />
  );
};

export default ShutdownTime;
