import Edit from 'assets/icons/Edit';
import classNames from 'classnames';
import COLOR from 'constants/color';
import { Mode } from 'constants/enum';
import { POST_HOG_EVENT } from 'constants/events';
import { ROUTINE } from 'constants/general';
import { t } from 'i18next';
import { ActivityType } from 'interfaces/commonInterface';
import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { routineSuggestionSelector } from 'store/reducer/setting/selectors';
import {
  configureTabs,
  updateShowEditSuggestionsModal
} from 'store/reducer/setting/slice';
import { captureEvents } from 'utils/events';
import { formatRoutineTimeSuggestions } from 'utils/routineSuggestionUtil';

interface RoutineSuggestionProps {
  readonly suggestion: ActivityType;
  readonly handleDuration: (value: string, activity_id: string) => void;
}

const RoutineSuggestion = ({
  suggestion,
  handleDuration
}: RoutineSuggestionProps) => {
  const dispatch = useAppDispatch();
  const [duration, setDuration] = useState({
    isEditable: false,
    value: suggestion.duration_seconds,
    isInvalid: !suggestion.duration_seconds
  });
  const { platform, userEmail, userId } = useAppSelector(
    routineSuggestionSelector
  );

  const handleToggle = useCallback(
    () => setDuration((prev) => ({ ...prev, isEditable: !prev.isEditable })),
    []
  );

  const handleEditHabitName = () => {
    dispatch(
      configureTabs({
        mode: Mode.CUSTOM,
        routine: ROUTINE.SUGGESTION
      })
    );
    dispatch(updateShowEditSuggestionsModal(true));
    captureEvents(
      POST_HOG_EVENT.ROUTINE_SUGGESTIONS_HABIT_TITLE_EDIT_HABIT_BUTTON_CLICKED,
      userEmail,
      { platform, userId }
    );
  };

  const hasCompletionRequirement = useMemo(
    () => Boolean(suggestion.completion_requirements),
    [suggestion]
  );

  return (
    <div className='w-full h-fit flex items-center gap-4 p-3 rounded-md shadow-md relative'>
      <p
        className={classNames(
          'w-fit ~max-w-[70%]/[80%] h-fit ~text-xs/base truncate',
          {
            'self-start': duration.isEditable,
            'self-center': !duration.isEditable
          }
        )}
      >
        {suggestion.name}
        {!hasCompletionRequirement ? (
          <button
            onClick={handleEditHabitName}
            className='inline-block ml-1.5 align-middle'
          >
            <Edit styles='w-4 h-4' />
          </button>
        ) : null}
      </p>
      {!hasCompletionRequirement ? (
        <>
          {duration.isEditable ? (
            <div className='flex flex-col gap-0.5 pt-0 md:pt-0.5'>
              <input
                type='number'
                value={duration.value}
                className={classNames(
                  'w-28 px-2 ~py-0.5/-1 text-black outline-none ~text-[0.60rem]/xs text-center shadow rounded font-semibold min-w-max',
                  {
                    'bg-red-100 border border-red-600': duration.isInvalid,
                    'bg-gray-50': !duration.isInvalid
                  }
                )}
                onBlur={handleToggle}
                min={1}
                onChange={({ target: { value } }) => {
                  setDuration((prev) => ({
                    ...prev,
                    value: parseInt(value),
                    isInvalid: !value
                  }));
                  handleDuration(value, suggestion.id);
                }}
              />
              <p className='text-[0.6rem] self-end font-medium leading-[0.6rem]'>
                {t('seconds')}
              </p>
            </div>
          ) : (
            <p
              className={classNames(
                'min-w-max w-fit h-fit ~text-[0.60rem]/xs  text-white ~py-0.5/1 px-2 rounded shadow',
                {
                  'bg-red-600': duration.isInvalid,
                  'bg-gray-600': !duration.isInvalid
                }
              )}
            >
              {formatRoutineTimeSuggestions(suggestion.duration_seconds)}
              <button
                onClick={handleToggle}
                className='inline-block ml-1.5 align-middle'
              >
                <Edit styles='w-3 h-3' fill={COLOR.WHITE} />
              </button>
            </p>
          )}
        </>
      ) : (
        <p className='~text-[0.60rem]/xs font-semibold bg-sunRayFirst text-white rounded-md px-2 shadow select-none min-w-max'>
          {t('completion_requirement')}
        </p>
      )}
    </div>
  );
};

export default RoutineSuggestion;
