import { DEFAULT_HTML_ATTRIBUTES } from 'assets/data';
import { THEME_OPTION } from 'constants/enum';
import { t } from 'i18next';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateFilterSearchWord } from 'store/reducer/filter/slice';
import { isDarkModeActivated } from 'utils/validation';

const FilterItemName = () => {
  const dispatch = useAppDispatch();
  const {
    filter: { searchWord },
    setting: { themeMode }
  } = useAppSelector((state) => state);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  return (
    <div className='w-full h-fit font-medium relative'>
      <label
        className={`absolute -top-2.5 left-1/2 -translate-x-1/2 bg-gray-600 text-[10px] px-1.5 rounded-b-md leading-5 ${
          themeMode === THEME_OPTION.LIGHT && 'text-white'
        }`}
      >
        {t('habit_name')}
      </label>
      <input
        className={`min-w-[200px] w-full outline-none rounded py-2 px-2 text-sm ${
          shouldActivateDarkMode ? 'bg-gray-400' : 'bg-white'
        } text-black`}
        value={searchWord}
        type='text'
        onChange={({
          target: { value }
        }: React.ChangeEvent<HTMLInputElement>) => {
          dispatch(updateFilterSearchWord(value));
        }}
        placeholder={t('search')}
        {...DEFAULT_HTML_ATTRIBUTES}
      />
    </div>
  );
};

export default FilterItemName;
