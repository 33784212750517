import CloseCircle from 'assets/icons/CloseCircle';
import classNames from 'classnames';
import Button from 'components/common/buttons/Button';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import CheckBox from 'components/common/CheckBox';
import TextInput from 'components/common/inputs/TextInput';
import ModalOverlay from 'components/shared/ModalOverlay';
import COLOR from 'constants/color';
import { BTN_FB_SIZE } from 'constants/enum';
import { t } from 'i18next';
import { FC, PropsWithChildren, useContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  routineAvailableGoalsSelector,
  themeSelector
} from 'store/reducer/setting/selectors';
import { updateRoutineSuggestionPayload } from 'store/reducer/setting/slice';
import { updateLongTermGoals } from 'store/reducer/user/extra';
import { isDarkModeActivated } from 'utils/validation';
import { RoutineGoalsContext } from '.';
import { EMPTY_STRING } from 'constants/general';
import { getUniqueGoals } from 'utils/routineSuggestionUtil';

const Wrapper = ({ children }: PropsWithChildren<object>) => {
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  return (
    <div
      className={classNames(
        'relative top-1/2 -translate-y-1/2 m-auto flex w-11/12 sm:w-10/12 md:w-3/4 h-fit flex-col gap-1 p-4 sm:p-6 rounded-lg animate-fadeIn',
        {
          'bg-gray-600': shouldActivateDarkMode,
          'bg-sunRay': !shouldActivateDarkMode
        }
      )}
    >
      {children}
    </div>
  );
};

const AvailableGoalsModal: FC = () => {
  const dispatch = useAppDispatch();
  const { details, isLogTermGoalUpdating, routineSuggestionPayload } =
    useAppSelector(routineAvailableGoalsSelector);
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  const { routineData, setRoutineData } = useContext(RoutineGoalsContext);
  const [newGoal, setNewGoal] = useState(EMPTY_STRING);
  const [newGoals, setNewGoals] = useState<string[]>([]);
  const shouldDisableAddNewGoal =
    !newGoal ||
    [...newGoals, ...routineData.availableGoals].some(
      (goal) =>
        goal?.toLowerCase() === newGoal?.toLowerCase() || isLogTermGoalUpdating
    );
  function handleAddGoal(goal: string) {
    const selectedGoals = routineData.selectedGoals.includes(goal)
      ? routineData.selectedGoals.filter((prevGoal) => prevGoal !== goal)
      : [...routineData.selectedGoals, goal];
    setRoutineData?.((prev) => ({
      ...prev,
      selectedGoals
    }));
    dispatch(
      updateRoutineSuggestionPayload({
        ...routineSuggestionPayload,
        user_goals: [...selectedGoals]
      })
    );
  }

  return (
    <ModalOverlay styles='bg-transparent'>
      <Wrapper>
        <p className='text-sm sm:text-base md:text-lg'>
          {t(
            'routine_suggestion.are_there_other_goals_you_want_to_achieve_with_focus_bear'
          )}
        </p>
        <div className='w-full h-[65vh] md:h-[40vh] sm:h-fit flex flex-wrap justify-evenly gap-x-2 gap-y-2 rounded-lg shadow-md p-5 overflow-y-auto scrollbar-thin scrollbar-thumb-sunRayFirst border border-sunRayFirst'>
          {getUniqueGoals([...routineData.availableGoals, ...newGoals]).map(
            (goal, idx) => (
              <CheckBox
                key={goal + idx}
                checked={routineData.selectedGoals.includes(goal)}
                onChange={() => handleAddGoal(goal)}
                label={goal}
                labelStyles={classNames(
                  'w-fit max-w-full h-fit flex gap-1 items-start rounded-md px-2 py-1 shadow-md ~text-sm/base my-2 cursor-pointer capitalize',
                  {
                    'bg-gray-600 hover:bg-gray-600/80': shouldActivateDarkMode,
                    'bg-whiteLaceFirst hover:bg-whiteLaceSecond ':
                      !shouldActivateDarkMode
                  }
                )}
                inputStyles='h-5 cursor-pointer outline-none accent-sunRayFirst'
              />
            )
          )}
        </div>
        <div className='w-full flex items-center justify-center gap-3 flex-wrap self-center mt-3'>
          <TextInput
            disabled={isLogTermGoalUpdating}
            value={newGoal}
            onChange={({ target: { value } }) => setNewGoal(value)}
            className={classNames(
              'min-w-[25vw] outline-none rounded-md shadow px-2 py-1 md:py-2 text-xs text-black',
              {
                'bg-gray-400 focus:bg-gray-400/80': shouldActivateDarkMode,
                'bg-white': !shouldActivateDarkMode
              }
            )}
            placeholder={t('routine_suggestion.long_term_goal')}
          />
          <ButtonFocusBear
            disabled={shouldDisableAddNewGoal}
            size={BTN_FB_SIZE.SMALL}
            onClick={() => {
              setNewGoal(EMPTY_STRING);
              setNewGoals((prev) => [...prev, newGoal]);
            }}
            title={t('add_goal')}
          />
        </div>

        {isLogTermGoalUpdating ? (
          <span className='w-5 h-5 rounded-full border-orange-600 border-t-2 animate-spin mx-auto mt-3'></span>
        ) : (
          <ButtonFocusBear
            size={BTN_FB_SIZE.SMALL}
            onClick={async () => {
              dispatch(
                updateLongTermGoals([
                  ...(details.long_term_goals ?? []),
                  ...newGoals
                ])
              );
            }}
            title={t('save_goals')}
            disabled={!newGoals.length}
            additionalStyles={`self-center mt-3 ${newGoals.length ? 'inline-flex' : 'hidden'} animate-fadeIn`}
          />
        )}
        <Button
          className='bg-sunRay rounded-full w-fit absolute -top-2 -right-2'
          icon={<CloseCircle fill={COLOR.BLACK} fillDarkMode={COLOR.RED} />}
          onClick={() =>
            setRoutineData?.((prev) => ({ ...prev, showAvailableGoals: false }))
          }
        />
      </Wrapper>
    </ModalOverlay>
  );
};

export default AvailableGoalsModal;
