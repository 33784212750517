import classNames from 'classnames';
import Tooltip from 'components/common/Tooltip';
import { t } from 'i18next';
import { useAppSelector } from 'store/hooks';
import { timingSummarySelector } from 'store/reducer/setting/selectors';
import { getTimeSummaries } from 'utils/settingsUtil';
import { createHtml } from 'utils/support';

const TimingSummary = () => {
  const {
    startup_time,
    shutdown_time,
    cutoff_time_for_non_high_priority_activities,
    morning_activities,
    evening_activities
  } = useAppSelector(timingSummarySelector);

  const summaries = getTimeSummaries({
    startup_time,
    shutdown_time,
    cutoff_time_for_non_high_priority_activities,
    evening_activities,
    morning_activities
  });

  return (
    <div className='w-full max-w-full text-sm flex flex-col gap-4 mt-3 md:mt-6 lg:mt-8 shadow-md py-2 sm:py-3 md:py-4 px-2 sm:px-4 md:px-6 rounded-md'>
      {summaries.map(({ title, tooltip }) => (
        <p
          key={tooltip}
          className={classNames('text-sm sm:text-base', {
            'font-semibold': tooltip === t('summary.tool_tip_free_time')
          })}
        >
          {createHtml(title)}
          <Tooltip
            message={tooltip}
            iconStyles='w-4 sm:w-5 h-4 sm:h-5 inline-flex ml-1.5'
            additionalWrapperStyles='w-fit'
          />
        </p>
      ))}
    </div>
  );
};

export default TimingSummary;
