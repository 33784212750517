import { ROUTINE, TAB } from 'constants/general';
import { PropsWithChildren } from 'react';
import { useAppSelector } from 'store/hooks';
import { tabBodyHeaderWidgetsSelector } from 'store/reducer/setting/selectors';
import AdvancedBtn from '../AdvancedBtn';
import FilterItemDaysOfWeek from './FilterItemDaysOfWeek';
import FilterItemName from './FilterItemName';
import FilterItemPriority from './FilterItemPriority';
import FilterWidgetActions from './FilterWidgetActions';

const WidgetWrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-fit h-fit flex items-start justify-end gap-2 absolute -top-3 ~right-2/2.5'>
    {children}
  </div>
);

const ToolbarWrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-fit max-w-[75vw] h-fit flex flex-col md:flex-row sm:text-center justify-between ~gap-6/4 bg-gray-600 p-3 rounded-b-2xl animate-slideIn z-20 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-400'>
    {children}
  </div>
);

const TabBodyHeaderWidgets = () => {
  const { isSettingToolbarShown, activeTabIndex, tabs, customRoutine } =
    useAppSelector(tabBodyHeaderWidgetsSelector);

  const isRoutineSuggestions = customRoutine === ROUTINE.SUGGESTION;

  return (
    <WidgetWrapper>
      {!isRoutineSuggestions ? <AdvancedBtn /> : null}
      {isSettingToolbarShown && (
        <ToolbarWrapper>
          {TAB.MICRO_BREAKS !== tabs[activeTabIndex] && (
            <FilterItemDaysOfWeek />
          )}
          <FilterItemPriority />
          <FilterItemName />
        </ToolbarWrapper>
      )}
      <FilterWidgetActions />
    </WidgetWrapper>
  );
};

export default TabBodyHeaderWidgets;
