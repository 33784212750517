import { incrementMotivationalDelayCounter } from './slice';
import { store } from 'store';
import {
  GENERAL_COUNTER_INTERVAL_MILLISECONDS,
  MOTIVATIONAL_SUMMARY_DELAY_SECONDS
} from 'constants/general';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { startGeneralCounterSelector } from './selector';
import { updateIsMotivationalSummaryStopGenerating } from '../user/slice';

export const startGeneralCounter = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  abortController: AbortController
) => {
  const intervalId = setInterval(() => {
    const { shouldStopMotivationalDelayCounter, motivationalDelayCounter } =
      startGeneralCounterSelector(store.getState());

    if (
      shouldStopMotivationalDelayCounter ||
      motivationalDelayCounter >= MOTIVATIONAL_SUMMARY_DELAY_SECONDS
    ) {
      abortController.abort();
      dispatch(updateIsMotivationalSummaryStopGenerating(true));
      clearInterval(intervalId);
    } else {
      dispatch(incrementMotivationalDelayCounter());
    }
  }, GENERAL_COUNTER_INTERVAL_MILLISECONDS);
};
