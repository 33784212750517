export const Button = {
  ADD_NEW_ACTIVITY: {
    WIDTH: 30,
    HEIGHT: 30
  },
  CHOICE_OPEN_URL: {
    WIDTH: 15,
    HEIGHT: 15
  }
};
export const IMAGE = {
  MORNING: {
    WIDTH: 85,
    HEIGHT: 85
  },
  BREAK: {
    WIDTH: 85,
    HEIGHT: 85
  },
  EVENING: {
    WIDTH: 85,
    HEIGHT: 85
  },
  FOCUS_BEAR_LOGO: {
    DEFAULT: {
      WIDTH: 50,
      HEIGHT: 50
    },
    ACTIVITY_INFORMATION: {
      WIDTH: 60,
      HEIGHT: 60
    }
  },
  FOCUS_TODO: {
    WIDTH: 85,
    HEIGHT: 85
  }
};
export const IFRAME = {
  HEIGHT: {
    DEFAULT: 300,
    SMALL: 350,
    TABLET: 400,
    LARGE: 450,
    EXTRA_LARGE: 500,
    DOUBLE_EXTRA_LARGE: 600
  }
};
export const SWITCH = {
  WIDTH: {
    ALLOWED_WEBSITES: 50,
    ALLOWED_APPLICATIONS: 50,
    FOCUS_MODES: 50,
    IS_OFFICE_FRIENDLY: 50,
    INCLUDE_IN_EVERY_BREAK: 50,
    LOG_SUMMARY: 50,
    MORE_OPTION_LOG_QUANTITY_QUESTION: 50,
    CHOICE_LOG_QUANTITY_QUESTION: 30,
    MICRO_BREAK: 50,
    CHOICES_LOG_QUANTITY: 30,
    USE_TIMER: 30,
    TODO_STATUS: 40,
    ENABLE_BREAK_ON_PHONE: 40
  },
  HEIGHT: {
    ALLOWED_WEBSITES: 20,
    ALLOWED_APPLICATIONS: 20,
    FOCUS_MODES: 20,
    IS_OFFICE_FRIENDLY: 20,
    INCLUDE_IN_EVERY_BREAK: 20,
    LOG_SUMMARY: 20,
    MORE_OPTION_LOG_QUANTITY_QUESTION: 20,
    CHOICE_LOG_QUANTITY_QUESTION: 15,
    MICRO_BREAK: 20,
    CHOICES_LOG_QUANTITY: 15,
    USE_TIMER: 15,
    TODO_STATUS: 20,
    ENABLE_BREAK_ON_PHONE: 20
  }
};
export const ICONS = {
  HAMBURGER: {
    DEFAULT: {
      WIDTH: 40,
      HEIGHT: 40
    }
  },
  CHECKED_FILLED: {
    DEFAULT: {
      WIDTH: 35,
      HEIGHT: 35
    },
    MOBILE: {
      WIDTH: 24,
      HEIGHT: 24
    }
  },
  WELCOME_PAGE_DOWNLOAD_LINKS: {
    DESKTOP: {
      WIDTH: 30,
      HEIGHT: 30
    },
    MOBILE: {
      WIDTH: 20,
      HEIGHT: 20
    }
  },
  ADMIN_FILLED: {
    DEFAULT: {
      WIDTH: 25,
      HEIGHT: 25
    }
  },
  CHECK_MARK: {
    DEFAULT: {
      WIDTH: 20,
      HEIGHT: 20
    },
    ACTIVITY_SUMMARY: {
      WIDTH: 30,
      HEIGHT: 30
    }
  },
  CLONE: {
    DEFAULT: {
      WIDTH: 16,
      HEIGHT: 16
    }
  },
  GLOBAL_NO: {
    DEFAULT: {
      WIDTH: 25,
      HEIGHT: 25
    }
  },
  INSTALL_OUTLINE: {
    DEFAULT: {
      WIDTH: 30,
      HEIGHT: 30
    },
    EMBEDDED_VIEW: {
      WIDTH: 20,
      HEIGHT: 20
    }
  },
  NOTE: {
    DEFAULT: {
      WIDTH: 16,
      HEIGHT: 16
    }
  },
  PLAY_CIRCLE: {
    DEFAULT: {
      WIDTH: 24,
      HEIGHT: 24
    }
  },
  PLUS_CIRCLE: {
    DEFAULT: {
      WIDTH: 16,
      HEIGHT: 16
    },
    LOG_QUESTION: {
      WIDTH: 19,
      HEIGHT: 19
    },
    LOG_TERM_GOALS: {
      WIDTH: 22,
      HEIGHT: 22
    }
  },
  HELP_FILLED: {
    DEFAULT: {
      WIDTH: 15,
      HEIGHT: 15
    },
    LOG_QUESTION: {
      WIDTH: 20,
      HEIGHT: 20
    },
    LOG_SUMMARY: {
      WIDTH: 20,
      HEIGHT: 20
    }
  },
  ARROW_SUB_DOWN_LEFT: {
    DEFAULT: {
      WIDTH: 20,
      HEIGHT: 20
    }
  }
};
